<template>
  <div>
    <el-row :gutter="4" style="padding: 20px 0 0 28px">
      <el-col :span="24">
        <el-radio-group
          v-model="ruleForm.radio"
          style="margin-left: 100px"
          @change="changeEvent"
        >
          <el-radio :label="'1'">{{ $t("public.Enable") }}</el-radio>
          <el-radio :label="'0'">{{ $t("public.Forbidden") }}</el-radio>
        </el-radio-group>
      </el-col>
      <el-col :span="24" style="display: flex; margin: 10px 0 10px 15px">
        <span style="color: #f66c6c; margin-right: 5px">*</span>
        <span style="font-size: 14px; color: #807e7e">{{
          $t("DeviceManage.ClientPolicy.BlackWhiteList.ListType")
        }}</span>
        <div style="flot: left">
          <el-select
            v-model="valueSelect"
            @change="changeSelect"
            :disabled="!flagStatus"
            size="small"
          >
            <el-option
              value="0"
              :label="$t('DeviceManage.ClientPolicy.BlackWhiteList.BlackList')"
            ></el-option>
            <el-option
              value="1"
              :label="$t('DeviceManage.ClientPolicy.BlackWhiteList.WhiteList')"
            ></el-option>
          </el-select>
        </div>
      </el-col>
      <el-col :span="24" style="display: flex; margin: 10px 0 10px 15px">
        <span style="color: #f66c6c; margin-right: 5px">*</span>
        <span style="font-size: 14px; color: #807e7e">{{
          $t("DeviceManage.ClientPolicy.BlackWhiteList.Target")
        }}</span>
        <div style="flot: left; display: flex; align-items: center">
          <span>
            <el-checkbox
              style="margin-right: 30px"
              @change="Typecheck"
              v-model="checkAll"
              :label="0"
              :disabled="ruleForm.radio == 0"
              >{{
                $t(
                  "DeviceManage.DevicePolicy.AndroidPolicy.ApplybwList.AllApply"
                )
              }}</el-checkbox
            >
          </span>
          <span>
            <el-checkbox-group
              :disabled="ruleForm.radio == 0"
              style="margin-top: 0px"
              @change="changeRadio"
              v-model="targetArr"
            >
              <el-checkbox v-if="valueSelect == 0" :label="2">{{
                $t("DeviceManage.ClientPolicy.BlackWhiteList.NonReinforcedApps")
              }}</el-checkbox>
              <el-checkbox v-else :label="1">{{
                $t("DeviceManage.ClientPolicy.BlackWhiteList.ReinforcedApps")
              }}</el-checkbox>
              <el-checkbox v-if="valueSelect == 0" :label="20"
                >非安全域应用</el-checkbox
              >
              <el-checkbox v-else :label="10">安全域应用</el-checkbox>
            </el-checkbox-group>
          </span>
        </div>
      </el-col>
      <el-col :span="24" style="display: flex; margin: 10px 0 10px 15px">
        <span style="color: #f66c6c; margin-right: 5px">*</span>
        <span style="font-size: 14px; color: #807e7e">{{
          $t("DeviceManage.ClientPolicy.BlackWhiteList.AppList")
        }}</span>
        <div style="width: 500px; max-height: 218px; overflow: auto">
          <el-table
            :data="tableDate"
            ref="multipleTable"
            stripe
            size="mini"
            tooltip-effect="dark"
            class="sort_table"
            :header-cell-style="{ background: '#f7f8fa' }"
            style="width: 100%"
          >
            <el-table-column
              prop="name"
              :label="$t('public.ApplicationName')"
              width="160"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="identifier"
              :label="$t('public.ApplicationID')"
              width="160"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="name"
              :label="$t('public.Controls')"
              show-overflow-tooltip
            >
              <template v-slot="{ row }">
                <img
                  v-if="row.flag"
                  @click="deleteGeograp(row)"
                  src="@/images/icon_del.png"
                  alt=""
                  style="
                    width: 15px;
                    height: 15px;
                    margin-left: 5px;
                    cursor: pointer;
                  "
                />
              </template>
            </el-table-column>
          </el-table>
        </div>
        <el-button
          v-if="flagStatus"
          style="
            border-radius: 18px;
            width: 70px;
            height: 30px;
            margin-left: 10px;
          "
          size="small"
          type="primary"
          icon="el-icon-plus"
          @click="addApply"
          >{{
            $t("DeviceManage.ClientPolicy.BlackWhiteList.Application")
          }}</el-button
        >
      </el-col>
      <el-col :span="24" style="margin: 10px 25px; font-size: 14px">
        {{ $t("DeviceManage.ClientPolicy.TriggeringCondition") }}
        <span v-if="valueSelect == 0" style="font-size: 12px; color: #807e7e">{{
          $t("DeviceManage.ClientPolicy.BlackWhiteList.Message1")
        }}</span>
        <span v-else style="font-size: 12px; color: #807e7e">{{
          $t("DeviceManage.ClientPolicy.BlackWhiteList.Message2")
        }}</span>
      </el-col>
    </el-row>
    <div style="display: flex; padding: 10px 38px">
      <span style="color: #f66c6c; margin-right: 5px">*</span>
      <span style="width: 73px; font-size: 14px">{{
        $t("DeviceManage.ClientPolicy.ResponseAction")
      }}</span>
      <div style="width: 500px; margin: 0 10px 0 0px">
        <el-table
          ref="multipleTable"
          :data="dictData"
          stripe
          size="mini"
          tooltip-effect="dark"
          class="sort_table"
          :header-cell-style="{ background: '#f7f8fa' }"
          style="width: 100%"
        >
          <el-table-column
            :label="$t('DeviceManage.ClientPolicy.SerialNumber')"
            type="index"
            width="50"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="name"
            :label="$t('DeviceManage.ClientPolicy.ResponseAction1')"
            width="160"
            show-overflow-tooltip
          >
            <template v-slot="{ row }">
              <template v-if="row.name == '0'">
                {{ $t("DeviceManage.ClientPolicy.ResponseActionName0") }}
              </template>
              <template v-if="row.name == 1">
                {{ $t("DeviceManage.ClientPolicy.ResponseActionName1") }}
              </template>
              <template v-if="row.name == 2">
                {{ $t("DeviceManage.ClientPolicy.ResponseActionName2") }}
              </template>
              <template v-if="row.name == 3">
                {{ $t("DeviceManage.ClientPolicy.ResponseActionName3") }}
              </template>
              <template v-if="row.name == 4">
                {{ $t("DeviceManage.ClientPolicy.ResponseActionName4") }}
              </template>
              <template v-if="row.name == 5">
                {{ $t("DeviceManage.ClientPolicy.ResponseActionName5") }}
              </template>
              <template v-if="row.name == 6">
                {{ $t("DeviceManage.ClientPolicy.ResponseActionName6") }}
              </template>
            </template>
          </el-table-column>
          <el-table-column
            prop="timeOut"
            :label="$t('DeviceManage.ClientPolicy.LayTime1')"
            width="120"
            show-overflow-tooltip
          >
            <template v-slot="{ row }">
              {{ row.timeOut }}
            </template>
          </el-table-column>
          <el-table-column
            prop="name"
            :label="$t('public.Controls')"
            show-overflow-tooltip
          >
            <template v-slot="{ row }">
              <img
                v-if="row.flag && ruleForm.radio == 1"
                @click="deletes(row)"
                src="@/images/icon_del.png"
                alt=""
                style="
                  width: 15px;
                  height: 15px;
                  margin-left: 5px;
                  cursor: pointer;
                "
              />
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-button
        v-if="flagStatus"
        style="border-radius: 18px; width: 100px; height: 35px"
        size="small"
        type="primary"
        icon="el-icon-plus"
        @click="installFlag = true"
        >{{ $t("DeviceManage.ClientPolicy.ResponseAction1") }}</el-button
      >
    </div>
    <Eldialog
      v-if="geograpFlag"
      @handleClose="handleClose"
      :title="$t('DeviceManage.ClientPolicy.BlackWhiteList.SelectApp')"
      :dialogVisible="geograpFlag"
      :btnTitle="$t('public.Join')"
      :width="'900px'"
      :num="'470px'"
      :flagbtn="true"
      :cancelBtn="true"
      :determineBtn="true"
      @determine="deterGeograp"
    >
      <el-input
        size="small"
        v-model="inputText"
        style="width: 240px"
        @keyup.enter.native="searcInstall"
        :placeholder="
          $t('DeviceManage.ClientPolicy.BlackWhiteList.Placeholder')
        "
      ></el-input>
      <el-button
        style="margin-left: 5px"
        type="primary"
        size="small"
        @click="searcInstall"
        >{{ $t("public.Inquire") }}</el-button
      >
      <el-table
        ref="multipleTable"
        :data="dataForm"
        stripe
        size="mini"
        :row-key="getRowKeys"
        tooltip-effect="dark"
        class="sort_table"
        :header-cell-style="{ background: '#f7f8fa' }"
        style="width: 100%; margin-top: 10px"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          align="center"
          type="selection"
          width="60"
          :reserve-selection="true"
        >
        </el-table-column>
        <el-table-column
          prop="name"
          :label="$t('public.ApplicationName')"
          width="160"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="identifier"
          :label="$t('public.ApplicationID')"
          width="160"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="versionName"
          :label="$t('public.Version')"
          width="120"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="lastUpdateTime"
          :label="$t('public.ModificationTime')"
          show-overflow-tooltip
        >
        </el-table-column>
      </el-table>
      <pagination
        :page="dataQuery.currentPage"
        :limit="dataQuery.pageSize"
        :total="dataQuery.totle"
        :pageSizes="[10, 20, 30, 40]"
        v-on:handleChildGetList="handleParentGetLists"
      />
    </Eldialog>
    <Eldialog
      v-if="installFlag"
      @handleClose="handleClose"
      :title="$t('DeviceManage.ClientPolicy.MessageError1')"
      :dialogVisible="installFlag"
      :btnTitle="$t('public.Join')"
      :flagbtn="true"
      :cancelBtn="true"
      :determineBtn="true"
      @determine="determine"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
        style="padding: 0 120px; height: 100px"
      >
        <el-form-item :label="$t('DeviceManage.ClientPolicy.ResponseAction')">
          <el-select
            size="small"
            v-model="value"
            :placeholder="$t('DeviceManage.ClientPolicy.MessageError14')"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="
                $t(`DeviceManage.ClientPolicy.Selectoptions.${item.label}`)
              "
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('DeviceManage.ClientPolicy.LayTime')"
          prop="frequency"
        >
          <el-input
            style="width: 85px; margin-right: 10px"
            size="small"
            v-model="ruleForm.frequency"
          ></el-input>
          <el-select
            size="small"
            style="width: 120px"
            v-model="ruleForm.valueTime"
            :placeholder="$t('DeviceManage.ClientPolicy.MessageError14')"
          >
            <el-option
              v-for="item in time"
              :key="item.value"
              :label="$t(`DeviceManage.ClientPolicy.time.${item.label}`)"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </Eldialog>
    <dialog-info
      :dialogInfo="visibaelFlag"
      @handleClose="dialogClose"
      @determine="onchose"
      :titleInfo="titleInfo"
      :width="'240px'"
    /><!-- 提示消息组件 -->
  </div>
</template>

<script>
import pagination from "@/components/pagination/page.vue";
import dialogInfo from "@/components/promptMessage/index";
import Eldialog from "@/components/elDialog";
export default {
  components: {
    Eldialog,
    dialogInfo,
    pagination,
  },
  props: {
    dataList: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      dataQuery: {
        currentPage: 1,
        pageSize: 10,
        totle: 0,
      },
      list: [],
      content: [
        {
          operType: "0",
          frequency: "0",
          frequencyType: "min",
        },
      ],
      inputText: "",
      geograpFlag: false,
      dataForm: [],
      tableDate: [],
      checkList: [],
      visibaelFlag: false,
      options: [
        {
          value: "1",
          label: "告警提示",
        },
        {
          value: "2",
          label: "清除数据",
        },
        {
          value: "3",
          label: "强制注销",
        },
        {
          value: "4",
          label: "停用设备",
        },
        {
          value: "5",
          label: "恢复出厂",
        },
        {
          value: "6",
          label: "禁止访问",
        },
      ],
      time: [
        {
          value: "min",
          label: "分钟",
        },
        {
          value: "hour",
          label: "小时",
        },
        {
          value: "day",
          label: "日",
        },
      ],
      valueSelect: "0",
      value: "1",
      activeName: "first",
      flagStatus: false,
      installFlag: false,
      titleInfo: "",
      flagOp: true,
      multipleSelection: [],
      dictData: [
        {
          name: "0",
          timeOut: this.$t("DeviceManage.ClientPolicy.ZeroMin"),
          flag: false,
        },
      ],
      ruleForm: {
        name: "",
        radio: "0",
        valueTime: "min",
        frequency: "1",
        target: [],
      },
      checkAll: false,
      targetArr: [],
      checkChe: "",
      rules: {
        frequency: [
          {
            required: true,
            message: this.$t("DeviceManage.ClientPolicy.MessageError16"),
            trigger: "blur",
          },
          {
            required: true,
            pattern: /^([1-9]\d*|[0]{1,1})$/,
            message: this.$t("DeviceManage.ClientPolicy.MessageError17"),
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    if (this.dataList && this.dataList.use == 1) {
      console.log(this.dataList, "this.dataList.target");
      this.flagStatus = true;
      this.ruleForm.radio = this.dataList.use;
      this.valueSelect = this.dataList.type;
      this.ruleForm.target = this.dataList.target;
      let datcheck = this.ruleForm.target;
      console.log(this.dataList.target, "datcheckdatcheck");
      if (this.dataList.target) {
        let checkdet = this.dataList.target.split(",").map(Number);
        this.targetArr = checkdet;
        if (this.targetArr[0] == 0) {
          this.checkAll = true;
        }
      }
      this.list = this.dataList.list;
      let data = [];
      this.content = this.dataList.content;
      this.dataList.content.forEach((item, index) => {
        data.push({
          name: item.operType,
          timeOut:
            item.frequencyType == "min"
              ? item.frequency + this.$t("DeviceManage.ClientPolicy.Min")
              : item.frequencyType == "hour"
              ? item.frequency + this.$t("DeviceManage.ClientPolicy.Hour")
              : item.frequencyType == "day"
              ? item.frequency + this.$t("DeviceManage.ClientPolicy.Day")
              : "",
          flag: index !== 0,
        });
      });
      this.dictData = data;
      let newData = [];
      this.dataList.list.forEach((item, index) => {
        newData.push({
          name: item.name,
          identifier: item.identifier,
          flag: true,
        });
      });
      this.tableDate = newData;
    }
  },
  methods: {
    getRowKeys(row) {
      return row.id;
    },
    handleParentGetLists(page, limit) {
      this.dataQuery.currentPage = page;
      this.dataQuery.pageSize = limit;
      // 调用查询方法
      this.application();
    },
    TheCallback() {
      this.$emit("submitSave", {
        flag: this.ruleForm.radio,
        content: this.content,
        type: this.valueSelect,
        list: this.list,
        target: this.ruleForm.target,
      });
    },
    // v6.1.1
    Typecheck(val) {
      console.log(val, "----全选 true------");
      if (this.valueSelect == 0) {
        this.targetArr = val ? [0, 2, 20] : [];
      } else if (this.valueSelect == 1) {
        this.targetArr = val ? [0, 1, 10] : [];
      }
      var targetStr = JSON.stringify(this.targetArr);
      var targetObj = JSON.parse(targetStr);
      this.ruleForm.target = targetObj.toString();
      this.TheCallback();
    },
    changeRadio(val) {
      console.log(val, typeof val, "------********");
      if (this.checkAll) {
        if (this.targetArr.indexOf(0) === -1) {
          this.targetArr.push("0");
        }
        if (val.length !== 20 || val.length !== 10) {
          this.checkAll = false;
          this.targetArr = [];
        }
      }
      var targetStr = JSON.stringify(this.targetArr);
      var targetObj = JSON.parse(targetStr);
      this.ruleForm.target = targetObj.toString();
      this.TheCallback();
    },
    //查询
    searcInstall() {
      this.dataQuery.currentPage = 1;
      this.application();
    },
    addApply() {
      this.geograpFlag = true;
      this.dataQuery.currentPage = 1;
      this.application();
      this.multipleSelection = [];
    },
    async application() {
      var param = {
        searchName: this.inputText,
        osType: "20",
        currentPage: this.dataQuery.currentPage,
        pageSize: this.dataQuery.pageSize,
        orderColume: "lastUpdateTime",
        orderRule: "DESC",
      };
      const res = await this.$axios.post(
        "/httpServe/android/appLib/getDataInfo",
        param,
        true
      );
      this.dataQuery.totle = res.data.total;
      this.dataForm = res.data.content;
      this.$nextTick(() => {
        this.dataForm.forEach((item, index) => {
          this.list.forEach((i, j) => {
            if (item.id == i.id) {
              // this.$refs.multipleTable.toggleRowSelection(item, true)
            }
          });
        });
      });
    },
    deterGeograp() {
      this.multipleSelection.forEach((item, index) => {
        this.tableDate.push({
          name: item.name,
          identifier: item.identifier,
          flag: true,
        });
        this.list.push({
          id: item.id,
          name: item.name,
          identifier: item.identifier,
        });
      });
      var result = [];
      var obj = {};
      for (var i = 0; i < this.tableDate.length; i++) {
        // if (
        //   !obj[this.tableDate[i].name] &&
        //   !obj[this.tableDate[i].identifier]
        // ) {
        //   result.push(this.tableDate[i]);
        //   obj[this.tableDate[i].name] = true;
        //   obj[this.tableDate[i].identifier] = true;
        // } else if (!obj[this.tableDate[i].identifier]) {
        //   result.push(this.tableDate[i]);
        //   obj[this.tableDate[i].identifier] = true;
        // } else if (!obj[this.tableDate[i].name]) {
        //   result.push(this.tableDate[i]);
        //   obj[this.tableDate[i].name] = true;
        // }
        var item = this.tableDate[i];  
        var combinedKey = item.name + '-' + item.identifier; // 组合name和identifier作为键
        // 如果name和identifier的组合是唯一的，就加入result  
        if (!obj[combinedKey]) {  
          result.push(item);  
          obj[combinedKey] = true; // 标记这个组合已经处理过  
        }
      }
      this.tableDate = result;
      var Newresult = [];
      var Newobj = {};
      for (var i = 0; i < this.list.length; i++) {
        // if (!Newobj[this.list[i].name] && !Newobj[this.list[i].identifier]) {
        //   Newresult.push(this.list[i])
        //   Newobj[this.list[i].name] = true
        //   Newobj[this.list[i].identifier] = true
        // } else if (!Newobj[this.list[i].identifier]) {
        //   Newresult.push(this.list[i])
        //   Newobj[this.list[i].identifier] = true
        // } else if (!Newobj[this.list[i].name]) {
        //   Newresult.push(this.list[i])
        //   Newobj[this.list[i].name] = true
        // }
        var item = this.list[i];  
        var combinedKey = item.name + '-' + item.identifier; // 组合name和identifier作为键
        // 如果name和identifier的组合是唯一的，就加入result  
        if (!Newobj[combinedKey]) {  
          Newresult.push(item);  
          Newobj[combinedKey] = true; // 标记这个组合已经处理过  
        }
      }
      this.list = Newresult;
      var targetStr = this.targetArr;
      this.ruleForm.target = targetStr.toString();
      console.log(this.ruleForm.target, "this.ruleForm.target-------------");
      this.TheCallback();
      this.geograpFlag = false;
      this.$nextTick(() => {
        this.inputText = "";
      });
    },
    handleSelectionChange(val) {
      this.multipleSelection = [...val];
    },
    deleteGeograp(val) {
      this.tableDate.forEach((item, i) => {
        if (item.name === val.name) {
          // i 为选中的索引
          this.tableDate.splice(i, 1);
        }
      });
      this.list.forEach((item, i) => {
        if (item.name === val.name) {
          // i 为选中的索引
          this.list.splice(i, 1);
        }
      });
      this.TheCallback();
    },
    // 设备越狱
    changeEvent(val) {
      this.TheCallback();
      if (val == 1) {
        this.flagStatus = true;
      } else {
        this.flagStatus = false;
      }
    },
    changeSelect() {
      this.checkAll = false;
      this.targetArr = [];
      // this.tableDate=[]
      this.TheCallback();
    },
    deletes(val) {
      this.dictData.forEach((item, i) => {
        if (item.name === val.name) {
          // i 为选中的索引
          this.dictData.splice(i, 1);
          this.content.splice(i, 1);
        }
      });
      this.TheCallback();
    },
    determine() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.flagOp = true;
          for (let index = 0; index < this.dictData.length; index++) {
            if (this.dictData[index].name == this.value) {
              this.$message({
                message: this.$t("DeviceManage.ClientPolicy.MessageError13"),
                type: "error",
                offset: 100,
              });
              this.flagOp = false;
            }
          }
          if (this.flagOp) {
            this.$nextTick(() => {
              this.dictData.push({
                name: this.value,
                timeOut:
                  this.ruleForm.valueTime == "min"
                    ? this.ruleForm.frequency +
                      this.$t("DeviceManage.ClientPolicy.Min")
                    : this.ruleForm.valueTime == "hour"
                    ? this.ruleForm.frequency +
                      this.$t("DeviceManage.ClientPolicy.Hour")
                    : this.ruleForm.valueTime == "day"
                    ? this.ruleForm.frequency +
                      this.$t("DeviceManage.ClientPolicy.Day")
                    : "",
                flag: true,
              });
              this.content.push({
                operType: this.value,
                frequency: this.ruleForm.frequency,
                frequencyType: this.ruleForm.valueTime,
              });
            });
            this.$emit("submitSave", {
              flag: this.ruleForm.radio,
              content: this.content,
              type: this.valueSelect,
              list: this.list,
              target: this.ruleForm.target,
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleClose() {
      this.$set(this.ruleForm, "valueTime", "min");
      this.$set(this.ruleForm, "frequency", "1");
      this.installFlag = false;
      this.geograpFlag = false;
    },
    dialogClose() {
      this.visibaelFlag = false;
    },
    onchose() {
      this.visibaelFlag = false;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-table__empty-text {
  line-height: 20px;
  width: 100%;
  color: #909399;
}

::v-deep .el-form-item {
  margin-bottom: 10px !important;
}
::v-deep [data-v-63400706] .el-form-item__content {
  font-size: 0;
  display: flex;
  align-items: flex-end;
}
</style>
