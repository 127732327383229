<template>
  <div>
    <el-row :gutter="4" style="padding: 20px 0 10px 22px">
      <el-col :span="24">
        <el-radio-group
          v-model="ruleForm.radio"
          style="margin-left: 105px"
          @change="changeEvent"
        >
          <el-radio :label="'1'">{{ $t("public.Enable") }}</el-radio>
          <el-radio :label="'0'">{{ $t("public.Forbidden") }}</el-radio>
        </el-radio-group>
      </el-col>
      <div style="display: flex; padding: 10px 20px 10px 15px">
        <span style="color: #f66c6c; margin-right: 5px; font-size: 14px"
          >*</span
        >
        <span style="font-size: 14px; color: #807e7e">{{
          $t("DeviceManage.ClientPolicy.Tabs.Geographical") + "："
        }}</span>
        <div style="width: 350px; margin: 0 10px 0 3px">
          <el-table
            :data="tableDate"
            ref="multipleTable"
            stripe
            size="mini"
            tooltip-effect="dark"
            class="sort_table"
            :header-cell-style="{ background: '#f7f8fa' }"
            style="width: 100%; max-height: 218px; overflow: auto"
          >
            <el-table-column
              prop="name"
              :label="$t('DeviceManage.ClientPolicy.Name')"
              align="left"
              width="160"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              prop="name"
              :label="$t('public.Controls')"
              show-overflow-tooltip
            >
              <template v-slot="{ row }">
                <img
                  v-if="row.flag && ruleForm.radio == 1"
                  @click="deleteGeograp(row)"
                  src="@/images/icon_del.png"
                  alt=""
                  style="
                    width: 15px;
                    height: 15px;
                    margin-left: 5px;
                    cursor: pointer;
                  "
                />
              </template>
            </el-table-column>
          </el-table>
        </div>
        <el-button
          v-if="flagStatus"
          style="border-radius: 18px; width: 100px; height: 35px"
          size="small"
          type="primary"
          icon="el-icon-plus"
          @click="geograpClick"
          >{{ $t("DeviceManage.ClientPolicy.Tabs.Geographical") }}</el-button
        >
      </div>
      <el-col
        :span="24"
        style="margin: 10px 25px; font-size: 14px; color: #807e7e"
      >
        {{ $t("DeviceManage.ClientPolicy.TriggeringCondition") }}
        <span style="font-size: 12px; color: #807e7e">{{
          $t("DeviceManage.ClientPolicy.Geographical.Message1")
        }}</span>
      </el-col>
    </el-row>
    <div style="display: flex; padding: 0 34px">
      <span style="color: #f66c6c; margin-right: 5px">*</span>
      <span style="width: 73px; font-size: 14px; color: #807e7e">{{
        $t("DeviceManage.ClientPolicy.ResponseAction")
      }}</span>
      <div style="width: 500px; margin: 0 10px 0 0px">
        <el-table
          ref="multipleTable"
          :data="dictData"
          stripe
          size="mini"
          tooltip-effect="dark"
          class="sort_table"
          :header-cell-style="{ background: '#f7f8fa' }"
          style="width: 100%"
        >
          <el-table-column
            :label="$t('DeviceManage.ClientPolicy.SerialNumber')"
            type="index"
            width="50"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="name"
            :label="$t('DeviceManage.ClientPolicy.ResponseAction1')"
            width="160"
            show-overflow-tooltip
          >
            <template v-slot="{ row }">
              <template v-if="row.name == '0'">
                {{ $t("DeviceManage.ClientPolicy.ResponseActionName0") }}
              </template>
              <template v-if="row.name == 1">
                {{ $t("DeviceManage.ClientPolicy.ResponseActionName1") }}
              </template>
              <template v-if="row.name == 2">
                {{ $t("DeviceManage.ClientPolicy.ResponseActionName2") }}
              </template>
              <template v-if="row.name == 3">
                {{ $t("DeviceManage.ClientPolicy.ResponseActionName3") }}
              </template>
              <template v-if="row.name == 4">
                {{ $t("DeviceManage.ClientPolicy.ResponseActionName4") }}
              </template>
              <template v-if="row.name == 5">
                {{ $t("DeviceManage.ClientPolicy.ResponseActionName5") }}
              </template>
              <template v-if="row.name == 6">
                {{ $t("DeviceManage.ClientPolicy.ResponseActionName6") }}
              </template>
            </template>
          </el-table-column>
          <el-table-column
            prop="timeOut"
            :label="$t('DeviceManage.ClientPolicy.LayTime1')"
            width="120"
            show-overflow-tooltip
          >
            <template v-slot="{ row }">
              <template v-if="row.timeOut == 0">
                {{ 0 + $t("DeviceManage.ClientPolicy.Min") }}
              </template>
              <template v-else>
                {{ row.timeOut }}
              </template>
            </template>
          </el-table-column>
          <el-table-column
            prop="name"
            :label="$t('public.Controls')"
            show-overflow-tooltip
          >
            <template v-slot="{ row }">
              <img
                v-if="row.flag && ruleForm.radio == 1"
                @click="deletes(row)"
                src="@/images/icon_del.png"
                alt=""
                style="
                  width: 15px;
                  height: 15px;
                  margin-left: 5px;
                  cursor: pointer;
                "
              />
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-button
        v-if="flagStatus"
        style="border-radius: 18px; width: 100px; height: 35px"
        size="small"
        type="primary"
        icon="el-icon-plus"
        @click="installFlag = true"
        >{{ $t("DeviceManage.ClientPolicy.ResponseAction1") }}</el-button
      >
    </div>
    <Eldialog
      v-if="geograpFlag"
      @handleClose="handleClose"
      :title="$t('DeviceManage.ClientPolicy.Geographical.Message2')"
      :dialogVisible="geograpFlag"
      :btnTitle="$t('public.Join')"
      :width="'800px'"
      :num="'470px'"
      :flagbtn="true"
      :cancelBtn="true"
      :determineBtn="true"
      @determine="deterGeograp"
    >
      <el-row :gutter="4" class="elRow">
        <el-col :span="7.5">
          <el-input
            size="small"
            v-model="inputText"
            style="width: 250px"
            @keyup.enter.native="searcInstall"
            placeholder="请输入名称"
          ></el-input
        ></el-col>
        <el-col :span="2">
          <el-button
            style="margin-left: 5px"
            type="primary"
            size="small"
            @click="searcInstall"
            >查询</el-button
          >
        </el-col>
      </el-row>
      <el-row :gutter="4" class="elRow">
        <el-table
          ref="multipleTables"
          :data="dataform"
          stripe
          size="mini"
          tooltip-effect="dark"
          class="sort_table"
          :header-cell-style="{ background: '#f7f8fa' }"
          style="width: 100%"
          @selection-change="handleSelectionChange"
        >
          <el-table-column align="center" type="selection" width="55">
          </el-table-column>
          <el-table-column
            prop="name"
            :label="$t('DeviceManage.ClientPolicy.Name')"
            width="80"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="groupFullName"
            :label="$t('public.Section')"
            width="80"
            show-overflow-tooltip
          >
            <template v-slot="{ row }">
              <span v-if="row.groupFullName">{{
                row.groupFullName.substr(groupFullNameShowByIndex)
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="longitude"
            :label="$t('public.LatitudeLongitude')"
            width="120"
            show-overflow-tooltip
          >
            <template v-slot="{ row }">
              {{ row.longitude + "," + row.latitude }}
            </template>
          </el-table-column>
          <el-table-column
            prop="shape"
            :label="$t('DeviceManage.ClientPolicy.Geographical.Shape')"
            width="80"
            show-overflow-tooltip
          >
            <template v-slot="{ row }">
              <template v-if="row.shape == 0">
                {{ $t("DeviceManage.ClientPolicy.Geographical.Circle") }}
              </template>
              <template v-else>
                {{ $t("DeviceManage.ClientPolicy.Geographical.Rectangle") }}
              </template>
            </template>
          </el-table-column>
          <el-table-column
            prop="radius"
            :label="$t('DeviceManage.ClientPolicy.Geographical.Radius')"
            width="80"
            show-overflow-tooltip
          >
            <template v-slot="{ row }">
              {{
                row.radius + $t("DeviceManage.ClientPolicy.Geographical.Meter")
              }}
            </template>
          </el-table-column>
          <el-table-column
            prop="scope"
            :label="$t('DeviceManage.ClientPolicy.Geographical.Scope')"
            width="100"
            show-overflow-tooltip
          >
            <template v-slot="{ row }">
              <template v-if="row.scope == 0">
                {{ $t("DeviceManage.ClientPolicy.Geographical.Enter") }}
              </template>
              <template v-else>
                {{ $t("DeviceManage.ClientPolicy.Geographical.Out") }}
              </template>
            </template>
          </el-table-column>
          <el-table-column
            prop="lastUpdateTime"
            :label="$t('public.ModificationTime')"
            show-overflow-tooltip
          >
          </el-table-column>
        </el-table>
        <p style="margin-top: 5px">
          {{ $t("public.total") }}{{ dataform.length
          }}{{ $t("public.recordss") }}
        </p>
      </el-row>
    </Eldialog>
    <Eldialog
      v-if="installFlag"
      :num="'110px'" 
      @handleClose="handleClose"
      :title="$t('DeviceManage.ClientPolicy.MessageError1')"
      :dialogVisible="installFlag"
      :btnTitle="$t('public.Join')"
      :flagbtn="true"
      :cancelBtn="true"
      :determineBtn="true"
      @determine="determine"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
        style="padding: 0 120px; height: 100px"
      >
        <el-form-item :label="$t('DeviceManage.ClientPolicy.ResponseAction')">
          <el-select
            size="small"
            style="width: 215px"
            v-model="value"
            :placeholder="$t('DeviceManage.ClientPolicy.MessageError14')"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="
                $t(`DeviceManage.ClientPolicy.Selectoptions.${item.label}`)
              "
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          :label="$t('DeviceManage.ClientPolicy.LayTime')"
          prop="frequency"
        >
          <el-input
            style="width: 85px; margin-right: 10px"
            size="small"
            v-model="ruleForm.frequency"
          ></el-input>
          <el-select
            size="small"
            style="width: 120px"
            v-model="ruleForm.valueTime"
            :placeholder="$t('DeviceManage.ClientPolicy.MessageError14')"
          >
            <el-option
              v-for="item in time"
              :key="item.value"
              :label="$t(`DeviceManage.ClientPolicy.time.${item.label}`)"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </Eldialog>
    <dialog-info
      :dialogInfo="visibaelFlag"
      @handleClose="dialogClose"
      @determine="onchose"
      :titleInfo="titleInfo"
      :width="'240px'"
    /><!-- 提示消息组件 -->
  </div>
</template>

<script>
import dialogInfo from "@/components/promptMessage/index";
import Eldialog from "@/components/elDialog";
export default {
  components: {
    Eldialog,
    dialogInfo,
  },
  props: {
    dataList: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      inputText: "",
      groupFullNameShowByIndex: "", //用户部门截取的下标
      tableDate: [
        // {
        //   name: "123",
        //   flag: true,
        // },
      ],
      content: [
        {
          operType: "0",
          frequency: "0",
          frequencyType: "min",
        },
      ],
      multipleSelection: [],
      dataform: [],
      geograpFlag: false,
      visibaelFlag: false,
      options: [
        {
          value: "1",
          label: "告警提示",
        },
        {
          value: "2",
          label: "清除数据",
        },
        {
          value: "3",
          label: "强制注销",
        },
        {
          value: "4",
          label: "停用设备",
        },
        {
          value: "5",
          label: "恢复出厂",
        },
        {
          value: "6",
          label: "禁止访问",
        },
      ],
      time: [
        {
          value: "min",
          label: "分钟",
        },
        {
          value: "hour",
          label: "小时",
        },
        {
          value: "day",
          label: "日",
        },
      ],
      geoFenceIds: [],
      geofences: [],
      value: "1",
      activeName: "first",
      flagStatus: false,
      installFlag: false,
      titleInfo: "",
      flagOp: true,
      dictData: [
        {
          name: "0",
          timeOut: this.$t("DeviceManage.ClientPolicy.ZeroMin"),
          flag: false,
        },
      ],
      ruleForm: {
        name: "",
        radio: "0",
        valueTime: "min",
        frequency: "1",
      },
      rules: {
        frequency: [
          {
            required: true,
            message: this.$t("DeviceManage.ClientPolicy.MessageError16"),
            trigger: "blur",
          },
          {
            required: true,
            pattern: /^([1-9]\d*|[0]{1,1})$/,
            message: this.$t("DeviceManage.ClientPolicy.MessageError17"),
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    console.log(this.dataList);
    if (this.dataList && this.dataList.use == 1) {
      this.flagStatus = true;
      this.ruleForm.radio = this.dataList.use;
      this.content = this.dataList.content;
      let data = [];
      this.dataList.content.forEach((item, index) => {
        data.push({
          name: item.operType,
          timeOut:
            item.frequencyType == "min"
              ? item.frequency + this.$t("DeviceManage.ClientPolicy.Min")
              : item.frequencyType == "hour"
              ? item.frequency + this.$t("DeviceManage.ClientPolicy.Hour")
              : item.frequencyType == "day"
              ? item.frequency + this.$t("DeviceManage.ClientPolicy.Day")
              : "",
          flag: index !== 0,
        });
      });
      this.dictData = data;

      // this.dataList.geofences.forEach((item, index) => {
      //   newData.push({
      //     name: item.name,
      //     flag: true
      //   })
      // })
      // this.tableDate = newData
      this.geograpList(this.dataList.geofences);
    }
  },
  mounted() {
    this.groupFullNameShowByIndex = sessionStorage.getItem(
      "groupFullNameShowByIndex"
    );
  },
  methods: {
    searcInstall() {
      this.geograpClick();
    },
    async geograpList(data) {
      let newdata = [];
      data.forEach((item) => {
        newdata.push(item.id);
      });
      let params = {
        ids: newdata,
      };
      const res = await this.$axios.post(
        "/httpServe/geofence/selectNameList",
        params,
        true
      );
      let newData = [];
      res.data.forEach((item, index) => {
        newData.push({
          id: item.id,
          name: item.name,
          flag: true,
        });
        this.geoFenceIds.push(item.id);
      });
      console.log(newData);
      this.tableDate = newData;
      this.geofences = this.tableDate;
      console.log(this.geofences, "this.geofences");
    },
    async geograpClick() {
      this.titleInfo = this.$t("DeviceManage.ClientPolicy.MessageError3");
      this.geograpFlag = true;
      let params = {
        name: this.inputText,
        currentPage: 1,
        pageSize: 99999,
        orderColume: "lastUpdateTime",
        orderRule: "DESC",
      };
      const res = await this.$axios.post(
        "/httpServe/geofence/getDataInfo",
        params,
        true
      );
      this.dataform = res.data.content;
      this.$nextTick(() => {
        this.dataform.forEach((item, index) => {
          this.geofences.forEach((i, j) => {
            if (item.id == i.id) {
              this.$refs.multipleTables.toggleRowSelection(item, true);
            }
          });
        });
      });
    },
    handleSelectionChange(val) {
      console.log(val);
      this.multipleSelection = [...val];
    },
    deterGeograp() {
      this.geofences = []
      this.tableDate = []
      this.multipleSelection.forEach((item, index) => {
        this.tableDate.push({
          id: item.id,
          name: item.name,
          flag: true,
        });
        this.geofences.push({ id: item.id, name: item.name });
      });
      console.log(this.geofences);
      var result = [];
      var obj = {};
      for (var i = 0; i < this.geofences.length; i++) {
        if (!obj[this.geofences[i].name]) {
          result.push(this.geofences[i]);
          obj[this.geofences[i].name] = true;
        }
      }
      this.geofences = result;
      var newResult = [];
      var newObj = {};
      for (var i = 0; i < this.tableDate.length; i++) {
        if (!newObj[this.tableDate[i].name]) {
          newResult.push(this.tableDate[i]);
          newObj[this.tableDate[i].name] = true;
        }
      }
      this.tableDate = newResult;
      this.geoFenceIds = [];
      console.log(this.geofences);
      this.geofences.forEach((item) => {
        this.geoFenceIds.push(item.id);
      });
      this.geograpFlag = false;
      this.TheCallback();
    },
    deleteGeograp(val) {
      const filteredTableDate = this.tableDate.filter(
        (obj) => obj.id !== val.id
      );
      this.tableDate = filteredTableDate;
      console.log(this.tableDate, "删除地理围栏tableDate");
      const filteredGeofences = this.geofences.filter(
        (obj) => obj.id !== val.id
      );
      this.geofences = filteredGeofences;
      console.log(this.geofences, "删除地理围栏geofences");

      const filteredGeoFenceIds = this.geoFenceIds.filter(
        (obj) => obj !== val.id
      );
      this.geoFenceIds = filteredGeoFenceIds;
      console.log(this.geoFenceIds, "删除地理围栏geoFenceIds");

      const filteredMultiple = this.multipleSelection.filter(
        (obj) => obj.id !== val.id
      );
      this.multipleSelection = filteredMultiple;
      console.log(this.multipleSelection, "删除地理围栏multipleSelection");

      // // this.tableData.splice(index, 1); // 从数组中删除对应行
      // this.tableDate.forEach((item, i) => {
      //   console.log(val, "val删除地理围栏");

      //   console.log(item, "item删除地理围栏");
      //   if (item.id === val.id) {
      //     // i 为选中的索引
      //     this.tableDate.splice(i, 1);
      //     this.geofences.splice(i, 1);
      //     this.geoFenceIds.splice(i, 1);
      //     this.multipleSelection.splice(i, 1);
      //   }
      // });
      this.TheCallback();
    },

    TheCallback() {
      this.$emit("submitSave", {
        flag: this.ruleForm.radio,
        content: this.content,
        geofences: this.geofences,
        geoFenceIds: this.geoFenceIds.join(","),
      });
    },
    // 设备越狱
    changeEvent(val) {
      console.log(this.ruleForm.radio);
      this.TheCallback();
      if (val == 1) {
        this.flagStatus = true;
      } else {
        this.flagStatus = false;
      }
    },
    deletes(val) {
      console.log(this.geofences);
      this.dictData.forEach((item, i) => {
        if (item.name === val.name) {
          // i 为选中的索引
          this.dictData.splice(i, 1);
          this.content.splice(i, 1);
        }
      });
      this.TheCallback();
      console.log(this.geofences);
    },
    determine() {
      this.$refs.ruleForm.validate((valid) => {
        console.log(this.geofences);
        if (valid) {
          this.flagOp = true;
          for (let index = 0; index < this.dictData.length; index++) {
            if (this.dictData[index].name == this.value) {
              this.$message({
                message: this.$t("DeviceManage.ClientPolicy.MessageError13"),
                type: "error",
                offset: 100,
              });
              this.flagOp = false;
            }
          }
          if (this.flagOp) {
            this.$nextTick(() => {
              this.dictData.push({
                name: this.value,
                timeOut:
                  this.ruleForm.valueTime == "min"
                    ? this.ruleForm.frequency +
                      this.$t("DeviceManage.ClientPolicy.Min")
                    : this.ruleForm.valueTime == "hour"
                    ? this.ruleForm.frequency +
                      this.$t("DeviceManage.ClientPolicy.Hour")
                    : this.ruleForm.valueTime == "day"
                    ? this.ruleForm.frequency +
                      this.$t("DeviceManage.ClientPolicy.Day")
                    : "",
                flag: true,
              });
              this.content.push({
                operType: this.value,
                frequency: this.ruleForm.frequency,
                frequencyType: this.ruleForm.valueTime,
              });
            });
            this.TheCallback();
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleClose() {
      this.$set(this.ruleForm, "valueTime", "min");
      this.$set(this.ruleForm, "frequency", "1");
      this.installFlag = false;
      this.geograpFlag = false;
    },
    dialogClose() {
      this.visibaelFlag = false;
    },
    onchose() {
      this.visibaelFlag = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.elRow {
  margin-bottom: 10px;
}
::v-deep .el-table__empty-text {
  line-height: 20px;
  width: 100%;
  color: #909399;
}
::v-deep .el-form-item {
  margin-bottom: 10px !important;
}
</style>
