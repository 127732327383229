<template>
  <div>
    <el-row :gutter="16">
      <el-col :span="24">
        <el-tabs
          v-model="activeName"
          type="card"
          class="tabs"
          @tab-click="handleClick"
        >
          <el-tab-pane :label="$t('DeviceManage.ClientPolicy.Tabs.BasicInformation')" name="first">
            <div style="padding: 30px 0 0 40px">
              <el-form
                :model="ruleForm"
                :rules="rules"
                ref="ruleForm"
                label-width="100px"
                class="box-content"
                @submit.native.prevent
              >
                <el-form-item :label="$t('DeviceManage.DevicePolicy.IOSPolicy.BasicInformation.PolicyName')+'：'" :required="true">
                  <el-input
                    size="small"
                    style="width: 260px;"
                    v-model="ruleForm.name"
                    maxlength="30"
                  ></el-input>
                  <span style="margin-left: 5px; color: #807e7e"
                    >{{$t('DeviceManage.DevicePolicy.IOSPolicy.BasicInformation.MessagePort')}}</span
                  >
                </el-form-item>
              </el-form>
            </div>
          </el-tab-pane>
          <el-tab-pane :label="$t('DeviceManage.ClientPolicy.Tabs.Equipment')" name="lockAt">
            <Equipment
              :dataList="
                configContents.configContent &&
                configContents.configContent.root
                  ? configContents.configContent.root
                  : {}
              "
              @submitSave="submitSave"
            />
          </el-tab-pane>
          <el-tab-pane :label="$t('DeviceManage.ClientPolicy.Tabs.SimChange')" name="lockBt">
            <SimChange
              :dataList="
                configContents.configContent && configContents.configContent.sim
                  ? configContents.configContent.sim
                  : {}
              "
              @submitSave="simSave"
            />
          </el-tab-pane>
          <el-tab-pane :label="$t('DeviceManage.ClientPolicy.Tabs.SdChange')" name="lockCt">
            <SdChange
              :dataList="
                configContents.configContent && configContents.configContent.sd
                  ? configContents.configContent.sd
                  : {}
              "
              @submitSave="sdSave"
            />
          </el-tab-pane>
          <el-tab-pane :label="$t('DeviceManage.ClientPolicy.Tabs.ViolationOutreach')" name="lockDt">
            <ViolationOutreach
              :dataList="
                configContents.configContent &&
                configContents.configContent.violation
                  ? configContents.configContent.violation
                  : {}
              "
              @submitSave="violation"
            />
          </el-tab-pane>
          <el-tab-pane :label="$t('DeviceManage.ClientPolicy.Tabs.Geographical')" name="lockEt">
            <Geographical
              :dataList="
                configContents.configContent &&
                configContents.configContent.geofence
                  ? configContents.configContent.geofence
                  : {}
              "
              @submitSave="geofence"
            />
          </el-tab-pane>
          <el-tab-pane :label="$t('DeviceManage.ClientPolicy.Tabs.SystemVersion')" name="lockFt">
            <SystemVersion
              :dataList="
                configContents.configContent &&
                configContents.configContent.version
                  ? configContents.configContent.version
                  : {}
              "
              :ostypeId="'an'"
              @submitSave="version"
            />
          </el-tab-pane>
          <el-tab-pane :label="$t('DeviceManage.ClientPolicy.Tabs.TrafficAnomaly')" name="lockTt">
            <TrafficAnomaly
              :dataList="
                configContents.configContent &&
                configContents.configContent.netflow
                  ? configContents.configContent.netflow
                  : {}
              "
              @submitSave="netflow"
            />
          </el-tab-pane>
          <el-tab-pane :label="$t('DeviceManage.ClientPolicy.Tabs.DeviceBattery')" name="lockQt">
            <DeviceBattery
              :dataList="
                configContents.configContent &&
                configContents.configContent.battery
                  ? configContents.configContent.battery
                  : {}
              "
              @submitSave="battery"
            />
          </el-tab-pane>

          <el-tab-pane :label="$t('DeviceManage.ClientPolicy.Tabs.StorageSpace')" name="lockUt">
            <StorageSpace
              :dataList="
                configContents.configContent &&
                configContents.configContent.capacity
                  ? configContents.configContent.capacity
                  : {}
              "
              @submitSave="capacity"
            />
          </el-tab-pane>
          <el-tab-pane :label="$t('DeviceManage.ClientPolicy.Tabs.ApplicationInstall')" name="lockLt">
            <ApplicationInstall
              :dataList="
                configContents.configContent &&
                configContents.configContent.install
                  ? configContents.configContent.install
                  : {}
              "
              @submitSave="install"
            />
          </el-tab-pane>
          <el-tab-pane :label="$t('DeviceManage.ClientPolicy.Tabs.SystemFunction')" name="lockMt">
            <SystemFunction
              :dataList="
                configContents.configContent &&
                configContents.configContent.systemFunctions
                  ? configContents.configContent.systemFunctions
                  : {}
              "
              :version="'Android'"
              @submitSave="systemFunctions"
            />
          </el-tab-pane>
          <el-tab-pane :label="$t('DeviceManage.ClientPolicy.Tabs.BlackWhiteList')" name="lockNt">
            <BlackWhiteList
              :dataList="
                configContents.configContent && configContents.configContent.bw
                  ? configContents.configContent.bw
                  : {}
              "
              @submitSave="bw"
            />
          </el-tab-pane>
          <el-tab-pane :label="$t('DeviceManage.ClientPolicy.Tabs.WifiBlackWhiteList')" name="lockRt">
            <WifiBlackWhiteList
              :dataList="
                configContents.configContent &&
                configContents.configContent.wifibw
                  ? configContents.configContent.wifibw
                  : {}
              "
              @submitSave="wifibw"
            />
          </el-tab-pane>
        </el-tabs>
      </el-col>
    </el-row>
        <el-button style="width: 100px; margin: 20px 90px" type="primary" size="small" @click="submitForm('ruleForm')"
          >{{$t('public.Save')}}</el-button
        >
    <Eldialog
      v-if="installFlag"
      @handleClose="handleClose"
      :title="$t('DeviceManage.ClientPolicy.MessageError1')"
      :dialogVisible="installFlag"
      :btnTitle="$t('public.Join')"
      :flagbtn="true"
      :cancelBtn="true"
      :determineBtn="true"
      @determine="determine"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
        style="padding: 0 120px"
      >
        <el-form-item :label="$t('DeviceManage.ClientPolicy.ResponseAction')">
          <el-select size="small" v-model="value" :placeholder="$t('DeviceManage.ClientPolicy.MessageError14')">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="$t(`DeviceManage.ClientPolicy.options.${item.label}`)"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('DeviceManage.ClientPolicy.LayTime')" prop="name">
          <el-input
            style="width: 85px; margin-right: 10px"
            size="small"
            v-model="frequency"
          ></el-input>
          <el-select
            size="small"
            style="width: 120px"
            v-model="valueTime"
            :placeholder="$t('DeviceManage.ClientPolicy.MessageError14')"
          >
            <el-option
              v-for="item in time"
              :key="item.value"
              :label="$t(`DeviceManage.ClientPolicy.time.${item.label}`)"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </Eldialog>
    <dialog-info
      :dialogInfo="visibaelFlag"
      @handleClose="dialogClose"
      @determine="onchose"
      :titleInfo="titleInfo"
      :width="'240px'"
    /><!-- 提示消息组件 -->
  </div>
</template>

<script>
import Equipment from "./ComplianceItem/Equipment.vue"; //设备越狱
import SimChange from "./ComplianceItem/SimChange.vue"; //sim卡变更
import SdChange from "./ComplianceItem/SdChange.vue"; //sD卡变更
import ViolationOutreach from "./ComplianceItem/ViolationOutreach.vue"; //违规外联
import Geographical from "./ComplianceItem/Geographical.vue"; //地理围栏
import SystemVersion from "./ComplianceItem/SystemVersion.vue"; //系统版本
import TrafficAnomaly from "./ComplianceItem/TrafficAnomaly.vue"; //流量异常
import DeviceBattery from "./ComplianceItem/DeviceBattery.vue"; //设备电量
import StorageSpace from "./ComplianceItem/StorageSpace.vue"; //储存空间
import ApplicationInstall from "./ComplianceItem/ApplicationInstall.vue"; //必装应用
import SystemFunction from "./ComplianceItem/SystemFunction.vue"; //系统功能
import BlackWhiteList from "./ComplianceItem/BlackWhiteList.vue"; //应用黑白名单
import WifiBlackWhiteList from "./ComplianceItem/WifiBlackWhiteList.vue"; //WIFI黑白名单
import dialogInfo from "@/components/promptMessage/index";
import Eldialog from "@/components/elDialog";
export default {
  components: {
    Eldialog,
    dialogInfo,
    Equipment,
    SimChange,
    SdChange,
    ViolationOutreach,
    Geographical,
    SystemVersion,
    TrafficAnomaly,
    DeviceBattery,
    StorageSpace,
    ApplicationInstall,
    SystemFunction,
    BlackWhiteList,
    WifiBlackWhiteList,
  },
  props: {
    configContents: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      submitFlag: false,
      visibaelFlag: false,
      options: [
        {
          value: "1",
          label: "告警提示",
        },
        {
          value: "2",
          label: "清除数据",
        },
        {
          value: "3",
          label: "强制注销",
        },
        {
          value: "4",
          label: "停用设备",
        },
        {
          value: "5",
          label: "恢复出厂",
        },
      ],
      time: [
        {
          value: "min",
          label: "分钟",
        },
        {
          value: "hour",
          label: "小时",
        },
        {
          value: "day",
          label: "日",
        },
      ],
      value: "1",
      valueTime: "min",
      frequency: "1",
      activeName: "first",
      flagStatus: false,
      installFlag: false,
      titleInfo: "",
      flagOp: true,
      configContent: {
        battery: { use: "0" },
        bw: { use: "0" },
        capacity: { use: "0" },
        geofence: { use: "0" },
        geofences: [],
        install: { use: "0" },
        netflow: { use: "0" },
        root: { use: "0" },
        sd: { use: "0" },
        sim: { use: "0" },
        systemFunctions: { use: "0" },
        times: [],
        version: { use: "0" },
        violation: { use: "0" },
        wifibw: { use: "0" },
      },
      geoFenceIds: "",
      dictData: [
        {
          name: this.$t('DeviceManage.ClientPolicy.ZeroMin'),
          timeOut: "0",
          flag: false,
        },
      ],
      ruleForm: {
        name: "",
        radio: 2,
      },
      rules: {
        name: [
          { required: true, message: this.$t('DeviceManage.DevicePolicy.IOSPolicy.BasicInformation.placeholder'), trigger: "blur" },
          {
            min: 1,
            max: 30,
            message: this.$t('DeviceManage.DevicePolicy.IOSPolicy.BasicInformation.MessagePort'),
            trigger: "blur",
          },
          {
            required: true,
            pattern: /^[\u4e00-\u9fa5_a-zA-Z0-9.·-]+$/,
            message: this.$t('DeviceManage.ClientPolicy.MessageError15'),
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    if (this.configContents.name) {
      console.log(this.configContents);
      this.ruleForm.name = this.configContents.name;
      this.configContent = this.configContents.configContent;
      this.geoFenceIds = this.configContents.geoFenceIds;
    } else {
      console.log("无值");
    }
  },
  methods: {
    backLoces() {
      this.submitFlag = false;
      if (!this.ruleForm.name) {
        this.$message({
          message: this.$t('DeviceManage.DevicePolicy.MessageError1'),
          type: "error",
          offset: 100,
        });
        this.$nextTick(() => {
          this.activeName = "first";
        });
        return false;
      }
      if (!/^[\u4e00-\u9fa5_a-zA-Z0-9.·-]+$/.test(this.ruleForm.name)) {
        this.$message({
          message: this.$t('DeviceManage.DevicePolicy.MessageError2'),
          type: "error",
          offset: 100,
        });
        this.$nextTick(() => {
          this.activeName = "first";
        });
        return false;
      }
      if (
        this.configContent.violation.use == 1 &&
        !this.configContent.violation.outerNetService
      ) {
        this.$message({
          message: this.$t('DeviceManage.ClientPolicy.MessageError2'),
          type: "error",
          offset: 100,
        });
        this.$nextTick(() => {
          this.activeName = "lockDt";
        });
        return false;
      }
      if (this.configContent.violation.use == 1) {
        if (
          !/(http|https):\/\/([\w.]+\/?)\S*/.test(
            this.configContent.violation.outerNetService
          )
        ) {
          this.$message({
            message: this.$t('DeviceManage.ClientPolicy.MessageError18'),
            type: "error",
            offset: 100,
          });
          this.$nextTick(() => {
            this.activeName = "lockDt";
          });
          return false;
        }
      }
      if (
        this.configContent.geofence.use == 1 &&
        this.configContent.geofence.geofences.length == 0
      ) {
        this.$message({
          message: this.$t('DeviceManage.ClientPolicy.MessageError3'),
          type: "error",
          offset: 100,
        });
        this.$nextTick(() => {
          this.activeName = "lockEt";
        });
        return false;
      }
      if (this.configContent.version.use == 1) {
        if (this.configContent.version.type == "between") {
          if (
            !this.configContent.version.startVersion &&
            !this.configContent.version.toVersion
          ) {
            this.$message({
              message: this.$t('DeviceManage.ClientPolicy.MessageError4'),
              type: "error",
              offset: 100,
            });
            this.$nextTick(() => {
              this.activeName = "lockFt";
            });
            return false;
          }
          // if (
          //   this.configContent.version.startVersion.split(".").join("") >
          //   this.configContent.version.toVersion.split(".").join("")
          // ) {
          //   this.titleInfo = "版本区间错误";
          //   this.visibaelFlag = true;
          //   this.$nextTick(() => {
          //     this.activeName = "lockFt";
          //   });
          //   return false;
          // }
        }
        if (
          this.configContent.version.type == "minVersion" &&
          !this.configContent.version.minVersion
        ) {
          this.$message({
            message: this.$t('DeviceManage.ClientPolicy.MessageError5'),
            type: "error",
            offset: 100,
          });
          this.$nextTick(() => {
            this.activeName = "lockFt";
          });
          return false;
        }
        if (
          this.configContent.version.type == "maxVersion" &&
          !this.configContent.version.maxVersion
        ) {
          this.$message({
            message: this.$t('DeviceManage.ClientPolicy.MessageError6'),
            type: "error",
            offset: 100,
          });
          this.$nextTick(() => {
            this.activeName = "lockFt";
          });
          return false;
        }
      }
      if (
        this.configContent.netflow.use == 1 &&
        !this.configContent.netflow.dayflow
      ) {
        this.$message({
          message: this.$t('DeviceManage.ClientPolicy.MessageError7'),
          type: "error",
          offset: 100,
        });
        this.$nextTick(() => {
          this.activeName = "lockTt";
        });
        return false;
      }
      // /(^(\d|[1-9]\d)(\.\d{1,2})?$)|(^100$)/
      if (this.configContent.battery.use == 1) {
        if (!this.configContent.battery.percent) {
          this.$message({
            message: this.$t('DeviceManage.ClientPolicy.MessageError8'),
            type: "error",
            offset: 100,
          });
          this.$nextTick(() => {
            this.activeName = "lockQt";
          });
          return false;
        }
        if (
          !/^([1-9]\d*|[0]{1,1})$/gi.test(this.configContent.battery.percent)
        ) {
          this.$message({
            message: this.$t('DeviceManage.ClientPolicy.MessageError19'),
            type: "error",
            offset: 100,
          });
          this.$nextTick(() => {
            this.activeName = "lockQt";
          });
          return false;
        }
      }
      if (this.configContent.capacity.use == 1) {
        if (!this.configContent.capacity.available) {
          this.$message({
            message: this.$t('DeviceManage.ClientPolicy.MessageError9'),
            type: "error",
            offset: 100,
          });
          this.$nextTick(() => {
            this.activeName = "lockUt";
          });
          return false;
        }
        if (
          !/^([1-9]\d*|[0]{1,1})$/gi.test(this.configContent.capacity.available)
        ) {
          this.$message({
            message: this.$t('DeviceManage.ClientPolicy.MessageError20'),
            type: "error",
            offset: 100,
          });
          this.$nextTick(() => {
            this.activeName = "lockUt";
          });
          return false;
        }
      }
      if (
        this.configContent.systemFunctions.use == 1 &&
        this.configContent.systemFunctions.functionlist.length == 0
      ) {
        this.$message({
          message: this.$t('DeviceManage.ClientPolicy.MessageError10'),
          type: "error",
          offset: 100,
        });
        this.$nextTick(() => {
          this.activeName = "lockMt";
        });
        return false;
      }
      if (
        this.configContent.bw.use == 1 &&
        this.configContent.bw.target == ''
      ) {
        this.$message({
          message: this.$t('DeviceManage.ClientPolicy.MessageError23'),
          type: "error",
          offset: 100,
        });
        this.$nextTick(() => {
          this.activeName = "lockNt";
        });
        return false;
      }
      if (
        this.configContent.bw.use == 1 &&
        this.configContent.bw.list.length == 0
      ) {
        this.$message({
          message: this.$t('DeviceManage.ClientPolicy.MessageError11'),
          type: "error",
          offset: 100,
        });
        this.$nextTick(() => {
          this.activeName = "lockNt";
        });
        return false;
      }
      if (this.configContent.wifibw.use == 1) {
        console.log(this.configContent.wifibw);
        if (
          this.configContent.wifibw.connType == "0" &&
          !this.configContent.wifibw.ssidStr
        ) {
          this.$message({
            message: this.$t('DeviceManage.ClientPolicy.MessageError12'),
            type: "error",
            offset: 100,
          });
          this.$nextTick(() => {
            this.activeName = "lockRt";
          });
          return false;
        }
        if (
          this.configContent.wifibw.connType == "1" &&
          !this.configContent.wifibw.macStr
        ) {
          this.$message({
            message: this.$t('DeviceManage.ClientPolicy.MessageError12'),
            type: "error",
            offset: 100,
          });
          this.$nextTick(() => {
            this.activeName = "lockRt";
          });
          return false;
        }
        if (
          this.configContent.wifibw.connType == "1" &&
          this.configContent.wifibw.macStr
        ) {
          let dataRecents = this.configContent.wifibw.macStr.split("\n");
          for (let index = 0; index < dataRecents.length; index++) {
            if (dataRecents[index] == dataRecents[index + 1]) {
              this.$message({
                message: this.$t('DeviceManage.ClientPolicy.MessageError21'),
                type: "error",
                offset: 100,
              });
              this.$nextTick(() => {
                this.activeName = "lockRt";
              });
              return false;
            }
            if (
              !/((([a-f0-9]{2}:){5})|(([a-f0-9]{2}-){5}))[a-f0-9]{2}/gi.test(
                dataRecents[index]
              )
            ) {
              this.$message({
                message: this.$t('DeviceManage.ClientPolicy.MessageError22'),
                type: "error",
                offset: 100,
              });
              this.$nextTick(() => {
                this.activeName = "lockRt";
              });
              return false;
            }
          }
        }
      }
      console.log(this.submitFlag);
      this.submitFlag = true;
    },
    handleClick(tab, event) {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.backLoces();
        } else {
          this.$nextTick(() => {
            this.activeName = "first";
          });
        }
      });
    },
    // 设备越狱
    changeEvent(val) {
      console.log(val);
      if (val == 1) {
        this.flagStatus = true;
      } else {
        this.flagStatus = false;
      }
    },
    // 保存
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.backLoces();
          console.log(this.submitFlag);
          if (this.submitFlag) {
            this.saveEdit();
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    async saveEdit() {
      if (this.configContents.id) {
        let params = {
          id: this.configContents.id,
          configContent: this.configContent,
          configType: "2",
          name: this.ruleForm.name,
          scope: "20", //区分安卓和ios
          timeFenceIds: "",
          geoFenceIds: this.geoFenceIds,
        };
        const res = await this.$axios.post(
          "/httpServe/policy/update",
          params,
          true
        );
        console.log(res.code);
        if (res.code == 101) {
          this.$message({
            message: this.$t('DeviceManage.DevicePolicy.MessageError29'),
            type: "error",
            offset: 100,
          });
        }
        if (res.code == 200) {
          this.$message({
            message: this.$t('public.SuccessfullySaved'),
            type: "success",
            offset: 100,
          });
          setTimeout(() => {
            this.$emit("goBack");
          }, 2000);
        }
      } else {
        let params = {
          name: this.ruleForm.name,
          configContent: this.configContent,
          configType: "2",
          scope: "20", //区分安卓和ios
          timeFenceIds: "",
          geoFenceIds: this.geoFenceIds,
        };
        const res = await this.$axios.post(
          "/httpServe/policy/save",
          params,
          true
        );
        console.log(res);
        if (res.code == 101) {
          this.$message({
            message: this.$t('DeviceManage.DevicePolicy.MessageError29'),
            type: "error",
            offset: 100,
          });
        } else {
          this.$message({
            message: this.$t('public.SuccessfullySaved'),
            type: "success",
            offset: 100,
          });
          setTimeout(() => {
            this.$emit("goBack");
          }, 2000);
        }
      }
    },
    deletes(val) {
      this.dictData.forEach((item, i) => {
        if (item.name === val.name) {
          // i 为选中的索引
          this.dictData.splice(i, 1);
        }
      });
    },
    determine() {
      this.flagOp = true;
      for (let index = 0; index < this.dictData.length; index++) {
        if (this.dictData[index].name == this.value) {
          this.$message({
            message: this.$t('DeviceManage.ClientPolicy.MessageError13'),
            type: "error",
            offset: 100,
          });
          this.flagOp = false;
        }
      }
      if (this.flagOp) {
        this.$nextTick(() => {
          this.dictData.push({
            name: this.value,
            timeOut:
              this.valueTime == "min"
                ? this.frequency + this.$t('DeviceManage.ClientPolicy.Min')
                : this.valueTime == "hour"
                ? this.frequency + this.$t('DeviceManage.ClientPolicy.Hour')
                : this.valueTime == "day"
                ? this.frequency + this.$t('DeviceManage.ClientPolicy.Day')
                : "",
            flag: true,
          });
        });
        console.log(this.dictData);
      }
    },
    // 设备越狱回调
    submitSave(val) {
      this.configContent.root = {
        content: val.content,
        use: val.flag,
      };
    },
    // SIM卡变更
    simSave(val) {
      this.configContent.sim = {
        content: val.content,
        use: val.flag,
      };
    },
    // sd卡变更
    sdSave(val) {
      this.configContent.sd = {
        content: val.content,
        use: val.flag,
      };
    },
    // 违规外联
    violation(val) {
      this.configContent.violation = {
        content: val.content,
        use: val.flag,
        outerNetService: val.outerNetService,
      };
    },

    // 地理围栏
    geofence(val) {
      console.log(val);
      this.configContent.geofence = {
        content: val.content,
        use: val.flag,
        geofences: val.geofences,
      };
      this.geoFenceIds = val.geoFenceIds;
    },
    //系统版本
    version(val) {
      this.configContent.version = {
        content: val.content,
        use: val.flag,
        type: val.type,
        startVersion: val.startVersion,
        toVersion: val.toVersion,
        minVersion: val.minVersion,
        maxVersion: val.maxVersion,
      };
    }, // 流量异常
    netflow(val) {
      this.configContent.netflow = {
        content: val.content,
        use: val.flag,
        dayflow: val.dayflow,
      };
      console.log(this.configContent.netflow);
    },
    //设备电量
    battery(val) {
      this.configContent.battery = {
        content: val.content,
        use: val.flag,
        percent: val.percent,
      };
      console.log(this.configContent.battery);
    },
    //储存空间
    capacity(val) {
      this.configContent.capacity = {
        content: val.content,
        use: val.flag,
        available: val.available,
      };
      console.log(this.configContent.capacity);
    },
    //必装应用
    install(val) {
      this.configContent.install = {
        content: val.content,
        use: val.flag,
      };
      console.log(this.configContent);
    },
    //系统功能
    systemFunctions(val) {
      this.configContent.systemFunctions = {
        content: val.content,
        use: val.flag,
        functionlist: val.functionlist,
      };
      console.log(this.configContent);
    },
    //应用黑白名单
    bw(val) {
      this.configContent.bw = {
        type: val.type,
        content: val.content,
        use: val.flag,
        list: val.list,
        target: val.target,
      };
      console.log(this.configContent.bw);
    },
    // wifi黑白名单
    wifibw(val) {
      this.configContent.wifibw = {
        type: val.type,
        content: val.content,
        use: val.flag,
        connType: val.connType,
        ssidStr: val.ssidStr,
        macStr: val.macStr,
      };
      console.log(this.configContent);
    },
    handleClose() {
      this.installFlag = false;
    },
    dialogClose() {
      this.visibaelFlag = false;
    },
    onchose() {
      this.visibaelFlag = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.tabs {
  border: 1px solid #dddd;
  border-radius: 5px;
  margin: 10px;
  margin-bottom: 0;
  clear: both;
  padding-bottom: 10px;
  height: 580px;
}
::v-deep .el-tabs__header {
  padding: 0;
  position: relative;
  margin: 0 0 10px;
}
::v-deep .el-tabs__item {
  padding: 0 10px;
  height: 36px;
  box-sizing: border-box;
  line-height: 36px;
  display: inline-block;
  list-style: none;
  font-size: 12px;
  font-weight: 500;
  color: #999999;
  position: relative;
}
::v-deep .el-tabs__item.is-active {
  color: #000;
}
::v-deep .el-tabs__item:hover {
  color: #000;
}
::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
  border-bottom-color: #fff;
  border-top: 2px solid #1d89e3;
}
::v-deep .el-tabs--card > .el-tabs__header .el-tabs__nav {
  border: 1px solid #e4e7ed;
  border-bottom: none;
  border-radius: 4px 4px 0 0;
  box-sizing: border-box;
  border-top: none;
}
// ::v-deep .el-form-item__label {
//   font-size: 16px;
//   margin-bottom: -5px;
// }
::v-deep .box-content .el-form-item {
  margin-bottom: 17px !important;
}
::v-deep .el-tabs__item {
  font-size: 12px !important;
}
::v-deep .el-tabs--card > .el-tabs__header .el-tabs__nav {
  border: 1px solid #e4e7ed;
  border-bottom: none;
  border-radius: 4px 4px 0 0;
  box-sizing: border-box;
  border-top: none;
  border-left:none;
}
</style>