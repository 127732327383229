<template>
  <div>
    <el-row :gutter="4" style="padding: 20px 0 10px 8px">
      <el-col :span="24">
        <el-radio-group v-model="ruleForm.radio" style="margin-left: 120px" @change="changeEvent">
          <el-radio :label="'1'">{{ $t('public.Enable') }}</el-radio>
          <el-radio :label="'0'">{{ $t('public.Forbidden') }}</el-radio>
        </el-radio-group>
      </el-col>
      <!-- 设备电量 -->
      <el-col :span="24" style="margin: 10px 0 10px 0">
        <el-form @submit.native.prevent :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">
          <el-form-item :label="$t('DeviceManage.ClientPolicy.DeviceBattery.BatteryPercentage')" :required="true">
            <el-input  @input="changeInput" :disabled="!flagStatus" v-model.trim="ruleForm.name" maxlength="3"
              style="width: 240px"
              oninput="if(isNaN(value)) { value = null } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}"
              size="small" :placeholder="$t('DeviceManage.ClientPolicy.DeviceBattery.Placeholder')"></el-input><span
              style="margin-left: 5px; color: #807e7e">%</span>
          </el-form-item>
          <el-form-item :label="$t('DeviceManage.ClientPolicy.TriggeringCondition')">
            <span
              style="font-size: 12px; color: #807e7e">{{ $t('DeviceManage.ClientPolicy.DeviceBattery.Message1') }}</span>
          </el-form-item>
          <el-form-item :label="$t('DeviceManage.ClientPolicy.ResponseAction')" :required="true">
            <div style="display: flex">
              <div style="width: 499px">
                <el-table ref="multipleTable" :data="dictData" stripe size="mini" tooltip-effect="dark" class="sort_table"
                  :header-cell-style="{ background: '#f7f8fa' }" style="width: 499px;line-height:0px">
                  <el-table-column :label="$t('DeviceManage.ClientPolicy.SerialNumber')" type="index" width="50"
                    align="center">
                  </el-table-column>
                  <el-table-column header-align="left" align="left" prop="name"
                    :label="$t('DeviceManage.ClientPolicy.ResponseAction1')" width="160" show-overflow-tooltip>
                    <template v-slot="{ row }">
                      <template v-if="row.name == '0'">
                        {{ $t('DeviceManage.ClientPolicy.ResponseActionName0') }}
                      </template>
                      <template v-if="row.name == 1"> {{ $t('DeviceManage.ClientPolicy.ResponseActionName1') }} </template>
                      <template v-if="row.name == 2"> {{ $t('DeviceManage.ClientPolicy.ResponseActionName2') }} </template>
                      <template v-if="row.name == 3"> {{ $t('DeviceManage.ClientPolicy.ResponseActionName3') }} </template>
                      <template v-if="row.name == 4"> {{ $t('DeviceManage.ClientPolicy.ResponseActionName4') }} </template>
                      <template v-if="row.name == 5"> {{ $t('DeviceManage.ClientPolicy.ResponseActionName5') }} </template>
                      <template v-if="row.name == 6"> {{ $t('DeviceManage.ClientPolicy.ResponseActionName6') }} </template>
                    </template>
                  </el-table-column>
                  <el-table-column header-align="left" align="left" prop="timeOut" :label="$t('DeviceManage.ClientPolicy.LayTime1')" width="120"
                    show-overflow-tooltip>
                    <template v-slot="{ row }">
                      {{ row.timeOut }}
                    </template>
                  </el-table-column>
                  <el-table-column header-align="left" align="left" prop="name" :label="$t('public.Controls')" show-overflow-tooltip>
                    <template v-slot="{ row }">
                      <img v-if="row.flag && ruleForm.radio == 1" @click="deletes(row)" src="@/images/icon_del.png" alt=""
                        style="
                          width: 15px;
                          height: 15px;
                          margin-left: 5px;
                          cursor: pointer;
                        " />
                    </template>
                  </el-table-column>
                </el-table>
              </div>
              <el-button v-if="flagStatus" style="
                  border-radius: 18px;
                  margin-left: 10px;
                  width: 100px;
                  height: 35px;
                " size="small" type="primary" icon="el-icon-plus" @click="installFlag = true">{{$t('DeviceManage.ClientPolicy.ResponseAction1')}}</el-button>
            </div>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <Eldialog v-if="installFlag" @handleClose="handleClose" :title="$t('DeviceManage.ClientPolicy.MessageError1')" :dialogVisible="installFlag"
      :btnTitle="$t('public.Join')" :flagbtn="true" :cancelBtn="true" :determineBtn="true" :num="'110px'" @determine="determine">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm"
        style="padding: 0 120px">
        <el-form-item :label="$t('DeviceManage.ClientPolicy.ResponseAction')">
          <el-select size="small" v-model="value" :placeholder="$t('DeviceManage.ClientPolicy.MessageError14')">
            <el-option v-for="item in options" :key="item.value" :label="$t(`DeviceManage.ClientPolicy.Selectoptions.${item.label}`)" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('DeviceManage.ClientPolicy.LayTime')" prop="frequency">
          <el-input style="width: 85px; margin-right: 10px" size="small" v-model="ruleForm.frequency"></el-input>
          <el-select size="small" style="width: 120px" v-model="ruleForm.valueTime" :placeholder="$t('DeviceManage.ClientPolicy.MessageError14')">
            <el-option v-for="item in time" :key="item.value" :label="$t(`DeviceManage.ClientPolicy.time.${item.label}`)" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </Eldialog>
    <dialog-info :dialogInfo="visibaelFlag" @handleClose="dialogClose" @determine="onchose" :titleInfo="titleInfo"
      :width="'240px'" /><!-- 提示消息组件 -->
  </div>
</template>

<script>
import dialogInfo from '@/components/promptMessage/index'
import Eldialog from '@/components/elDialog'
export default {
  components: {
    Eldialog,
    dialogInfo
  },
  props: {
    dataList: {
      type: Object,
      default: () => { }
    }
  },
  data() {
    return {
      visibaelFlag: false,
      options: [
        {
          value: '1',
          label: '告警提示'
        },
        {
          value: '2',
          label: '清除数据'
        },
        {
          value: '3',
          label: '强制注销'
        },
        {
          value: '4',
          label: '停用设备'
        },
        {
          value: '5',
          label: '恢复出厂'
        },
        {
          value: '6',
          label: '禁止访问'
        }
      ],
      time: [
        {
          value: 'min',
          label: '分钟'
        },
        {
          value: 'hour',
          label: '小时'
        },
        {
          value: 'day',
          label: '日'
        }
      ],
      content: [
        {
          operType: '0',
          frequency: '0',
          frequencyType: 'min'
        }
      ],
      value: '1',
      activeName: 'first',
      flagStatus: false,
      installFlag: false,
      titleInfo: '',
      flagOp: true,
      dictData: [
        {
          name: '0',
          timeOut: this.$t('DeviceManage.ClientPolicy.ZeroMin'),
          flag: false
        }
      ],
      ruleForm: {
        name: '',
        radio: '0',
        valueTime: 'min',
        frequency: '1'
      },
      rules: {
        name: [
          { required: true, message: this.$t('DeviceManage.ClientPolicy.DeviceBattery.Placeholder'), trigger: 'blur' },
          {
            required: true,
            pattern: /^\d+$|^\d+[.]?\d+$/,
            message: this.$t('DeviceManage.ClientPolicy.DeviceBattery.MessageError2'),
            trigger: 'blur'
          }
        ],
        frequency: [
          { required: true, message: this.$t('DeviceManage.ClientPolicy.MessageError16'), trigger: 'blur' },
          {
            required: true,
            pattern: /^([1-9]\d*|[0]{1,1})$/,
            message: this.$t('DeviceManage.ClientPolicy.MessageError17'),
            trigger: 'blur'
          }
        ]
      }
    };
  },
  created() {
    if (this.dataList && this.dataList.use == 1) {
      this.flagStatus = true
      this.ruleForm.radio = this.dataList.use
      this.ruleForm.name = this.dataList.percent
      this.content = this.dataList.content
      let data = []
      this.dataList.content.forEach((item, index) => {
        data.push({
          name: item.operType,
          timeOut:
            item.frequencyType == 'min'
              ? item.frequency + this.$t('DeviceManage.ClientPolicy.Min')
              : item.frequencyType == 'hour'
                ? item.frequency + this.$t('DeviceManage.ClientPolicy.Hour')
                : item.frequencyType == 'day'
                  ? item.frequency + this.$t('DeviceManage.ClientPolicy.Day')
                  : '',
          flag: index !== 0
        })
      })
      this.dictData = data
    }
  },
  methods: {
    TheCallback() {
      this.$emit('submitSave', {
        flag: this.ruleForm.radio,
        content: this.content,
        percent: this.ruleForm.name
      })
    },
    // 设备越狱
    changeEvent(val) {
      this.TheCallback()
      if (val == 1) {
        this.flagStatus = true
      } else {
        this.flagStatus = false
      }
    },
    changeInput() {
      if (this.ruleForm.name == 0) {
        this.ruleForm.name = ''
      }
      if (this.ruleForm.name > 100) {
        let data = this.ruleForm.name.slice(0, 2)
        this.ruleForm.name = data
      }
      this.TheCallback()
    },
    deletes(val) {
      this.dictData.forEach((item, i) => {
        if (item.name === val.name) {
          // i 为选中的索引
          this.dictData.splice(i, 1)
          this.content.splice(i, 1)
        }
      })
      this.TheCallback()
    },
    determine() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.flagOp = true
          for (let index = 0; index < this.dictData.length; index++) {
            if (this.dictData[index].name == this.value) {
              this.$message({
                message: this.$t('DeviceManage.ClientPolicy.MessageError13'),
                type: 'error',
                offset: 100
              })
              this.flagOp = false
            }
          }
          if (this.flagOp) {
            this.$nextTick(() => {
              this.dictData.push({
                name: this.value,
                timeOut:
                  this.ruleForm.valueTime == 'min'
                    ? this.ruleForm.frequency + this.$t('DeviceManage.ClientPolicy.Min')
                    : this.ruleForm.valueTime == 'hour'
                      ? this.ruleForm.frequency + this.$t('DeviceManage.ClientPolicy.Hour')
                      : this.ruleForm.valueTime == 'day'
                        ? this.ruleForm.frequency + this.$t('DeviceManage.ClientPolicy.Day')
                        : '',
                flag: true
              })
              this.content.push({
                operType: this.value,
                frequency: this.ruleForm.frequency,
                frequencyType: this.ruleForm.valueTime
              })
            })
            this.$emit('submitSave', {
              flag: this.ruleForm.radio,
              content: this.content,
              percent: this.ruleForm.name
            })
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    handleClose() {
      this.$set(this.ruleForm, 'valueTime', 'min')
      this.$set(this.ruleForm, 'frequency', '1')
      this.installFlag = false
    },
    dialogClose() {
      this.visibaelFlag = false
    },
    onchose() {
      this.visibaelFlag = false
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-form-item {
  margin-bottom: 10px !important;
}

::v-deep .el-form-item__label {
  color: #2c3e50;
}</style>