<template>
  <div class="processManage">
    <!-- <navi-gation /> -->
    <!-- 合规策略 -->
    <div v-if="show == 0">
      <el-row :gutter="4" style="padding: 10px 10px">
        <el-col :span="3">
          <el-input
            v-model.trim="queryObj.searchContent"
            size="small"
            :placeholder="$t('DeviceManage.DevicePolicy.placeholder')"
            @keyup.enter.native="searchList"
          ></el-input>
        </el-col>
        <el-col :span="4">
          <el-button size="small" type="primary" @click="searchList">{{
            $t("public.Inquire")
          }}</el-button>
        </el-col>
      </el-row>
      <el-row :gutter="16" style="padding: 0 0 10px 10px">
        <el-col :span="24">
          <el-button-group>
            <el-button type="primary" size="small" @click="addAndroid">{{
              $t("DeviceManage.DevicePolicy.AndroidBtn")
            }}</el-button>
            <el-button type="primary" size="small" @click="addIos">{{
              $t("DeviceManage.DevicePolicy.iOSBtn")
            }}</el-button>
            <el-button
              :type="typeInfo"
              @click="distributions"
              :disabled="!hasSeleted"
              size="small"
              >{{ $t("public.Distribution") }}</el-button
            >
            <el-button
              @click="deletes"
              :type="typeInfo"
              :disabled="!hasSeleted"
              size="small"
              >{{ $t("public.Delete") }}</el-button
            >
          </el-button-group>
        </el-col>
      </el-row>
      <el-row class="formBox" :gutter="16" style="padding: 0 20px">
        <el-table
          :cell-style="{ padding: '3px 0px' }"
          :header-row-style="{ height: '30px' }"
          ref="multipleTable"
          :row-key="getRowKeys"
          :data="dictData"
          stripe
          size="mini"
          tooltip-effect="dark"
          class="tableClass"
          :default-sort="{ prop: 'lastUpdateTime', order: 'descending' }"
          @sort-change="sort"
          @selection-change="handleSelectionChange"
          style="width: 100%"
        >
          <el-table-column
            type="selection"
            align="center"
            :reserve-selection="true"
            width="55"
          >
          </el-table-column>
          <el-table-column
            prop="name"
            :label="$t('DeviceManage.DevicePolicy.Name')"
            width="120"
            sortable="custom"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            prop="scopeStr"
            :label="$t('public.OperatingSystem')"
            width="120"
            show-overflow-tooltip
            sortable="custom"
          >
            <template v-slot="{ row }">
              {{ row.scopeStr }}
            </template>
          </el-table-column>
          <el-table-column
            prop="groupFullName"
            :label="$t('public.Section')"
            width="120"
            sortable="custom"
            show-overflow-tooltip
          >
            <template v-slot="{ row }">
              <span v-if="row.groupFullName">{{
                row.groupFullName.substr(groupFullNameShowByIndex)
              }}</span>
            </template>
          </el-table-column>
          <!-- <el-table-column
            header-align="left"
            align="left"
            prop="executeAndAllocated"
            label="执行/分配"
            width="120"
            show-overflow-tooltip
          >
            <template v-slot="{ row }">
              <template v-if="row.executeAndAllocated == '0/0'">
                {{ row.executeAndAllocated }}
              </template>
              <template v-else>
                <span
                  style="color: #d38a08; cursor: pointer"
                  @click="perform(row)"
                  >{{ row.executeAndAllocated }}</span
                >
              </template>
            </template>
          </el-table-column> -->
          <el-table-column
            prop="lastUpdateTime"
            :label="$t('public.ModificationTime')"
            width="140"
            show-overflow-tooltip
            sortable="custom"
          >
            <template v-slot="{ row }">
              <template v-if="row.lastUpdateTime">
                {{ row.lastUpdateTime }}
              </template>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('public.Controls')"
            header-align="left"
            align="left"
          >
            <template v-slot="{ row }">
              <span class="action_icon">
                <img
                  @click="perform(row)"
                  src="@/assets/icon_see.png"
                  style="width: 15px; height: 15px; cursor: pointer"
                  :title="$t('DeviceManage.DevicePolicy.ExecutionAllocation')"
                />
              </span>
              <span class="action_icon">
                <img
                  @click="distribution(row)"
                  src="@/images/icon_delivery.png"
                  :title="$t('public.Distribution')"
                  alt=""
                  style="
                  width: 15px;
                  height: 15px;
                  cursor: pointer;
                "
                />
              </span>
              <span class="action_icon">
                <img
                  @click="eedits(row)"
                  src="../../assets/icon_write.png"
                  alt=""
                  :title="$t('public.Editor')"
                  style="width: 15px; height: 15px; cursor: pointer"
                />
              </span>
              <span class="action_icon">
                <img
                  @click="deletes(row)"
                  src="@/images/icon_del.png"
                  :title="$t('public.Delete')"
                  alt=""
                  style="
                  width: 15px;
                  height: 15px;
                  cursor: pointer;
                "
                />
              </span>
            </template>
          </el-table-column>
        </el-table>
        <pagination
          :page="queryObj.currentPage"
          :limit="queryObj.pageSize"
          :total="totalCount"
          :pageSizes="[10, 20, 30, 40]"
          v-on:handleChildGetList="handleParentGetList"
        />
        <ul
          class="tableCheckBox"
          style=""
          v-if="isCheckShow"
          @mouseleave="leave"
        >
          <li @click="curSelection()">当前页全选</li>
          <li @click="toggleSelection()">取消全选</li>
        </ul>
      </el-row>
    </div>
    <chil-drens-and
      @goBack="goBack"
      :configContents="configContent"
      v-if="show == 1"
    />
    <chil-drens-ios
      @goBack="goBack"
      :configContents="configContent"
      v-if="show == 2"
    />
    <dialog-info
      :dialogInfo="visibaelFlag"
      @handleClose="handleClose"
      @determine="determine"
      :titleInfo="titleInfo"
      :width="'240px'"
    /><!-- 提示消息组件 -->
    <Eldialog
      @handleClose="handleClose"
      :title="titleInfo"
      :dialogVisible="installFlag"
      :num="'470px'"
      :width="'1000px'"
    >
      <div style="width: 100%" class="contBox">
        <div style="position: relative; border: 1px solid #dddd; height: 450px">
          <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
            <el-tab-pane :label="$t('public.Executed')" name="first">
              <el-table
                stripe
                size="mini"
                @sort-change="sortExecuteAndAll"
                tooltip-effect="dark"
                class="sort_table"
                :row-style="iRowStyle"
                :cell-style="iCellStyle"
                :header-row-style="iHeaderRowStyle"
                :header-cell-style="{
                  background: '#f7f8fa',
                  color: '#606266',
                  padding: '0px',
                }"
                :data="tableAn"
                style="width: 100%"
              >
                <el-table-column
                  prop="deviceName"
                  :label="$t('public.DeviceName')"
                  width="165"
                  show-overflow-tooltip
                >
                  <template v-slot="{ row }">
                    {{ row.deviceName }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="loginName"
                  :label="$t('public.Username')"
                  width="180"
                >
                  <template v-slot="{ row }">
                    {{ row.loginName }}
                  </template>
                </el-table-column>
                <el-table-column prop="userName" :label="$t('public.Name')">
                  <template v-slot="{ row }">
                    {{ row.userName }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="groupFullName"
                  :label="$t('public.Section')"
                  show-overflow-tooltip
                >
                  <template v-slot="{ row }">
                    <span v-if="row.groupFullName">
                      {{ row.groupFullName.substr(groupFullNameShowByIndex) }}
                    </span>
                  </template>
                </el-table-column>
              </el-table>
              <pagination
                :page="dataQuery.currentPage"
                :limit="dataQuery.pageSize"
                :total="dataQuery.totalCount"
                :pageSizes="[10, 20, 30, 40]"
                v-on:handleChildGetList="handleParentGetLists"
              />
            </el-tab-pane>
            <el-tab-pane :label="$t('public.Assigned')" name="second">
              <el-table
                stripe
                size="mini"
                @sort-change="sortExecuteAndAll"
                tooltip-effect="dark"
                class="sort_table"
                :row-style="iRowStyle"
                :cell-style="iCellStyle"
                :header-row-style="iHeaderRowStyle"
                :header-cell-style="{
                  background: '#f7f8fa',
                  color: '#606266',
                  padding: '0px',
                }"
                :data="tableAn1"
                style="width: 100%"
              >
                <el-table-column
                  prop="deviceName"
                  :label="$t('public.DeviceName')"
                  width="165"
                  show-overflow-tooltip
                >
                  <template v-slot="{ row }">
                    {{ row.deviceName }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="loginName"
                  :label="$t('public.Username')"
                  width="180"
                >
                  <template v-slot="{ row }">
                    {{ row.loginName }}
                  </template>
                </el-table-column>
                <el-table-column prop="userName" :label="$t('public.Name')">
                  <template v-slot="{ row }">
                    {{ row.userName }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="groupFullName"
                  :label="$t('public.Section')"
                  show-overflow-tooltip
                >
                  <template v-slot="{ row }">
                    <span v-if="row.groupFullName">
                      {{ row.groupFullName.substr(groupFullNameShowByIndex) }}
                    </span>
                  </template>
                </el-table-column>
              </el-table>
              <pagination
                :page="dataQuery.currentPage2"
                :limit="dataQuery.pageSize2"
                :total="dataQuery.totalCount"
                :pageSizes="[10, 20, 30, 40]"
                v-on:handleChildGetList="handleParentGetLists2"
              />
            </el-tab-pane>
          </el-tabs>
          <el-button
            class="psBtn"
            type="primary"
            size="small"
            @click="deriveOne()"
            style="margin-bottom: 10px"
            >{{ $t("public.Derive") }}</el-button
          >
          <el-input
            size="small"
            class="ipt"
            v-model.trim="deviceName"
            @keyup.enter.native="searcInstall"
            :placeholder="$t('DeviceManage.DevicePolicy.placeholderDeviceName')"
          >
            <i
              @click="searcInstall"
              slot="suffix"
              class="el-input__icon el-icon-search"
              style="cursor: pointer"
            ></i>
          </el-input>
        </div>
      </div>
    </Eldialog>
  </div>
</template>
<script>
import Eldialog from "@/components/elDialog";
import chilDrensAnd from "./components/complianItem.vue";
import chilDrensIos from "./components/complianIOs.vue";
import dialogInfo from "@/components/promptMessage/index";
import naviGation from "@/components/hearder/index";
import pagination from "@/components/pagination/page.vue";
export default {
  components: {
    Eldialog,
    pagination,
    naviGation,
    dialogInfo,
    chilDrensAnd,
    chilDrensIos,
  },
  data() {
    return {
      groupFullNameShowByIndex: "", //用户部门截取的下标
      dataQuery: {
        currentPage: 1,
        pageSize: 10,
        currentPage2: 1,
        pageSize2: 10,
        totalCount: 0,
        orderColume: "devicePolicy.createTime",
        orderRule: "DESC",
      },
      configContent: {},
      id: "",
      show: 0,
      searchIndex: "",
      activeName: "first",
      tableAn: [],
      tableAn1: [],
      typeInfo: "info",
      titleInfo: "",
      deviceName: "",
      dictData: [],
      multipleSelection: [],
      queryObj: {
        configType: "2",
        currentPage: 1,
        pageSize: 10,
        orderColume: "client.lastUpdateTime",
        orderRule: "DESC",
        searchContent: "",
      },
      totalCount: 0,
      // 遮罩全选
      isCheckShow: false,
      installFlag: false, //执行分配开关
      visibaelFlag: false, //提示消息开关
      flagSearch: true,
    };
  },
  computed: {
    hasSeleted() {
      return this.multipleSelection && this.multipleSelection.length;
    },
  },
  mounted() {
    this.groupFullNameShowByIndex = sessionStorage.getItem(
      "groupFullNameShowByIndex"
    );
    this.tableList();
  },
  methods: {
    // 导出
    async deriveOne() {
      console.log("已分配合规策略----");
      let token2 = sessionStorage.getItem("Authorization")
        ? sessionStorage.getItem("Authorization")
        : "eyJ0eXBlIjoiSldUIiwiYWxnIjoiSFM1MTIifQ.eyJleHAiOjE2MzMwMjMzNzUsInVzZXJJZCI6MSwiaWF0IjoxNjMzMDAxNzc1fQ.hSF4bNDHVFZR2h4R0osNBF56ChR_l03mwx9QYaS1E0JDtIFlme3dP8oktCjEIaGi4QzXutLRMmjFWxuDNtaMMA";

      let userString = decodeURIComponent(
        escape(window.atob(token2.split(".")[1]))
      );
      this.userId = JSON.parse(userString).userId;
      // if (this.searchIndex  == '0') {
      let params = {
        splitIndex: this.groupFullNameShowByIndex,
        configType: "2",
        searchContent: this.deviceName,
        id: this.id,
        currentPage: this.dataQuery.currentPage,
        pageSize: this.dataQuery.pageSize,
        status: 1,
        orderColume: this.dataQuery.orderColume,
        orderRule: this.dataQuery.orderRule,
      };
      const res = await this.$axios.postExport(
        "/httpManageExport/policy/device/exportFile",
        params,
        false
      );
      const link = document.createElement("a");
      var fileName = "exportPolicyInfo_" + Date.parse(new Date()) + ".xls";
      let blob = new Blob([res.data], { type: "application/vnd.ms-excel" });
      link.style.display = "none";
      link.href = URL.createObjectURL(blob);
      link.setAttribute("download", decodeURI(fileName));
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // }
    },
    getRowKeys(row) {
      return row.id;
    },
    //表格行高
    iRowStyle: function ({ row, rowIndex }) {
      return "height:30px";
    },
    iHeaderRowStyle: function ({ row, rowIndex }) {
      return "height:30px";
    },
    iCellStyle: function ({ row, column, rowIndex, columnIndex }) {
      return "padding:3px 0px";
    },
    goBack() {
      this.queryObj.searchContent = "";
      this.show = 0;
      this.tableList();
    },
    async perform(row) {
      this.id = row.id;
      this.titleInfo = this.$t("DeviceManage.DevicePolicy.allocation");
      this.activeName = "first";
      this.installFlag = true;
      this.handleClick({ index: "0" });
    },
    async handleClick(val) {
      if (this.flagSearch) {
        this.deviceName = "";
      }
      this.searchIndex = val.index;
      if (val.index == "1") {
        let params = {
          configType: "2",
          searchContent: this.deviceName,
          id: this.id,
          currentPage: this.dataQuery.currentPage2,
          pageSize: this.dataQuery.pageSize2,
          status: -1,
          orderColume: this.dataQuery.orderColume,
          orderRule: this.dataQuery.orderRule,
        };
        const res = await this.$axios.post(
          "/httpServe/policy/device/getDataInfo",
          params,
          true
        );
        console.log(res.data.content, "1111111111111");
        this.tableAn1 = res.data.content;
        this.dataQuery.totalCount = res.data.total;
      } else {
        let params = {
          configType: "2",
          searchContent: this.deviceName,
          id: this.id,
          currentPage: this.dataQuery.currentPage,
          pageSize: this.dataQuery.pageSize,
          status: 1,
          orderColume: this.dataQuery.orderColume,
          orderRule: this.dataQuery.orderRule,
        };
        const res = await this.$axios.post(
          "/httpServe/policy/device/getDataInfo",
          params,
          true
        );
        console.log(res.data.content, "000000000000");
        this.tableAn = res.data.content;
        this.dataQuery.totalCount = res.data.total;
      }
    },
    searcInstall() {
      if (this.activeName == "second") {
        this.dataQuery.currentPage = 1;
        this.flagSearch = false;
        this.handleClick({ index: "1" });
      } else {
        this.dataQuery.currentPage = 1;
        this.flagSearch = false;
        this.handleClick({ index: "2" });
      }
    },
    // 分配
    distribution(row) {
      let newData = [];
      newData.push({
        value: 2,
        idList: [row.id],
      });
      //判断一级 分配管理 名称是否能找到
      let arrName = this.$store.state.selectList.find(
        (item) => item.name == "Delivery"
      );
      if (arrName?.name) {
        arrName.children.forEach((item, index) => {
          if (item.name.indexOf("Delivery") != -1) {
            this.$router.push({
              name: "Delivery",
            });
          }
        });
        sessionStorage.setItem("complianceList", JSON.stringify(newData));
      }
    },
    // 分配多个
    distributions() {
      let data = [];
      this.multipleSelection.forEach((item, index) => {
        data.push(item.id);
      });
      let newData = [];
      newData.push({
        value: 2,
        idList: data,
      });
      //判断一级 分配管理 名称是否能找到
      let arrName = this.$store.state.selectList.find(
        (item) => item.name == "Delivery"
      );
      if (arrName?.name) {
        arrName.children.forEach((item, index) => {
          if (item.name.indexOf("Delivery") != -1) {
            this.$router.push({
              name: "Delivery",
            });
          }
        });
        sessionStorage.setItem("complianceList", JSON.stringify(newData));
      }
    },
    eedits(val) {
      if (val.scopeStr == "iOS") {
        this.configContent = val;
        this.show = 2;
      } else {
        this.configContent = val;
        this.show = 1;
      }
    },
    sort(column) {
      console.log(column.order, column.prop, "----");
      if (column.order === "ascending") {
        this.queryObj.orderRule = "ASC";
      } else if (column.order === "descending") {
        this.queryObj.orderRule = "DESC";
      }
      if (column.prop == "name") {
        this.queryObj.orderColume = "client.name";
      } else if (column.prop == "scopeStr") {
        this.queryObj.orderColume = "client.scope";
      } else if (column.prop == "groupFullName") {
        this.queryObj.orderColume = "g.groupFullName";
      } else if (column.prop == "lastUpdateTime") {
        this.queryObj.orderColume = "client.lastUpdateTime";
      }
      // this.queryObj.orderColume = column.prop;
      this.tableList();
    },
    sortExecuteAndAll(column) {
      console.log(column);
      if (column.order === "ascending") {
        this.dataQuery.orderRule = "ASC";
      } else if (column.order === "descending") {
        this.dataQuery.orderRule = "DESC";
      }
      if (column.prop == "deviceName") {
        this.dataQuery.orderColume = "device.deviceName";
      } else if (column.prop == "loginName") {
        this.dataQuery.orderColume = "userInfo.loginName";
      } else if (column.prop == "userName") {
        this.dataQuery.orderColume = "userInfo.userName";
      } else if (column.prop == "groupFullName") {
        this.dataQuery.orderColume = "g.groupFullName";
      }
      console.log(this.dataQuery.orderColume);
      this.searcInstall();
    },
    dataPublic(val) {
      this.queryObj.startTime = val.startTime;
      this.queryObj.endTime = val.endTime;
    },
    //请求列表
    async tableList() {
      const res = await this.$axios.post(
        "/httpServe/policy/getDataInfo",
        this.queryObj,
        true
      );
      console.log(res.data.content);
      this.$nextTick(() => {
        this.dictData = res.data.content;
        this.totalCount = res.data.total;
      });
    },
    async deleteBaths(row) {
      if (row.id) {
        let param = {
          ids: [row.id],
        };
        const res = await this.$axios.post(
          "/httpServe/policy/delete",
          param,
          true
        );
        this.$message({
          message: this.$t("public.SuccessfullyDeleted"),
          type: "success",
          offset: 100,
        });
        this.$refs.multipleTable.clearSelection();
        this.searchList();
      } else {
        let data = [];
        this.multipleSelection.forEach((item, index) => {
          data.push(item.id);
        });
        let param = {
          ids: data,
        };
        const res = await this.$axios.post(
          "/httpServe/policy/delete",
          param,
          true
        );
        if (this.multipleSelection.length > 1) {
          this.$message({
            message: this.$t("public.SuccessfullyDeleteds"),
            type: "success",
            offset: 100,
          });
          this.$refs.multipleTable.clearSelection();
          this.searchList();
        } else {
          this.$message({
            message: this.$t("public.SuccessfullyDeleted"),
            type: "success",
            offset: 100,
          });
          this.$refs.multipleTable.clearSelection();
          this.searchList();
        }
      }
    },
    searchList() {
      this.queryObj.currentPage = 1;
      this.tableList();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      this.typeInfo = "primary";
      if (this.multipleSelection.length == 0) {
        this.typeInfo = "info";
      }
    },
    // 响应分页组件查询事件
    handleParentGetList(page, limit) {
      this.queryObj.currentPage = page;
      this.queryObj.pageSize = limit;
      // 调用查询方法
      this.tableList();
    },
    handleParentGetLists(page, limit) {
      console.log(page,limit,'handleParentGetLists');
      this.dataQuery.currentPage = page;
      this.dataQuery.pageSize = limit;
      // 调用查询方法
      this.handleClick({ index: "0" });
    },
    handleParentGetLists2(page, limit) {
      console.log(page,limit,'handleParentGetLists2');
      this.dataQuery.currentPage2 = page;
      this.dataQuery.pageSize2= limit;
      // 调用查询方法
      this.handleClick({ index: "1" });
    },
    //全选遮罩层
    maskLayer() {
      this.isCheckShow = true;
    },
    // 鼠标移出弹框
    leave() {
      this.isCheckShow = false;
    },
    //当前页全选
    curSelection() {
      this.disabled = false;
      this.$refs.multipleTable.toggleAllSelection();
    },
    //所有页全选
    toggleAllSelection() {
      this.disabled = false;
      this.$refs.multipleTable.toggleAllSelection();
    },
    //新增Android策略
    addAndroid() {
      this.configContent = {};
      this.show = 1;
    },
    addIos() {
      this.configContent = {};
      this.show = 2;
    },
    //取消全选
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          row.forEach((item) => {
            item.checked = true;
          });
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
        this.disabled = false;
      }
      this.isCheckShow = true;
    },
    async downFile(row) {
      var param = {
        path: row.logFile,
      };
      const res = await this.$axios.post(
        "/httpServe/sysFile/fileDown",
        param,
        true
      );
      // console.log(res.data)
      await this.$axios.downloadFile(res.data);
    },
    //删除
    deletes(row) {
      let title = "";
      if (row.id) {
        title = this.$t("DeviceManage.DevicePolicy.CheckDel");
      } else {
        title = this.$t("DeviceManage.DevicePolicy.CheckDel1");
      }
      this.$confirm(title, this.$t("public.PromptMessage"), {
        confirmButtonText: this.$t("public.Verify"),
        cancelButtonText: this.$t("public.Cancel"),
        type: "warning",
        customClass: "persdsd",
      })
        .then(() => {
          this.deleteBaths(row);
        })
        .catch(() => {
          return false;
        });
    },
    handleClose() {
      this.deviceName = "";
      this.installFlag = false;
      this.visibaelFlag = false;
    },
    //确定回调事件
    determine() {
      this.visibaelFlag = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.mt15 {
  padding: 10px 10px;
}
.formBox {
  //全选弹框
  position: relative;
  .tableCheckBox {
    border: 1px solid #1e89e5;
    border-radius: 3px;
    width: 80px;
    background-color: #fff;
    position: absolute;
    top: 32px;
    left: 20px;
    z-index: 999;
    // background:rgba(255, 255, 255,.1)
    li {
      margin-top: -1px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      color: #575656;
      cursor: pointer;
      font-weight: 400;
      font-family: Microsoft YaHei;
      font-size: 12px;
      list-style: none !important;
      div {
        display: inline-block;
      }
    }
    li:hover {
      color: #d38a08;
      text-decoration: underline;
    }
  }
}
.processManage .callenia {
  height: 0;
}
.psBtn {
  // width: 180px;
  position: absolute;
  top: 4px;
  left: 175px;
}
.ipt {
  width: 200px;
  position: absolute;
  top: 2px;
  right: 3px;
}
::v-deep .el-tabs__item {
  font-size: 14px;
}
::v-deep .el-table__row > td {
  border: none;
}
::v-deep .el-table::before {
  height: 0px;
}
::v-deep .el-table th.is-leaf {
  border: none;
}
::v-deep .el-form-item__label {
  color: #807e7e;
}
</style>
