<template>
  <div>
    <el-row :gutter="4" style="padding: 20px 0 10px 33px">
      <el-col :span="24">
        <el-radio-group v-model="ruleForm.radio" style="margin-left: 95px" @change="changeEvent">
          <el-radio :label="'1'">{{ $t('public.Enable') }}</el-radio>
          <el-radio :label="'0'">{{ $t('public.Forbidden') }}</el-radio>
        </el-radio-group>
      </el-col>
      <el-col :span="24" style="display:flex;margin:10px 0 10px 15px;">
        <span style="color:#f66c6c;margin-right:5px;">*</span>
        <span
          style="font-size:14px;color:#807e7e;">{{ $t('DeviceManage.ClientPolicy.SystemFunction.ConnectionType') }}</span>
        <div style="flot:left;">
          <el-checkbox-group style="display:blok;" v-model="functionlist" @change="changeSelect">
            <el-checkbox v-if="version == 'Android'" label="bluetooth"
              :disabled="!flagStatus">{{ $t('DeviceManage.ClientPolicy.SystemFunction.bluetooth') }}</el-checkbox>
            <el-checkbox v-if="version == 'Android'" label="wifi"
              :disabled="!flagStatus">{{ $t('DeviceManage.ClientPolicy.SystemFunction.wifi') }}</el-checkbox>
            <el-checkbox v-if="version == 'Android'" label="dataLink"
              :disabled="!flagStatus">{{ $t('DeviceManage.ClientPolicy.SystemFunction.dataLink') }}</el-checkbox>
            <el-checkbox v-if="version == 'Android'" label="hotspot"
              :disabled="!flagStatus">{{ $t('DeviceManage.ClientPolicy.SystemFunction.hotspot') }}</el-checkbox>
            <el-checkbox v-if="version == 'Android'" label="location"
              :disabled="!flagStatus">{{ $t('DeviceManage.ClientPolicy.SystemFunction.location') }}</el-checkbox>
            <el-checkbox v-if="version == 'iOS'" label="bluetooth"
              :disabled="!flagStatus">{{ $t('DeviceManage.ClientPolicy.SystemFunction.bluetooth') }}</el-checkbox>
            <el-checkbox v-if="version == 'iOS'" label="dataLink"
              :disabled="!flagStatus">{{ $t('DeviceManage.ClientPolicy.SystemFunction.dataLink') }}</el-checkbox>
            <el-checkbox v-if="version == 'iOS'" label="wifi"
              :disabled="!flagStatus">{{ $t('DeviceManage.ClientPolicy.SystemFunction.useWifi') }}</el-checkbox>
            <el-checkbox v-if="version == 'iOS'" label="hotspot"
              :disabled="!flagStatus">{{ $t('DeviceManage.ClientPolicy.SystemFunction.hotspotConnection') }}</el-checkbox>
          </el-checkbox-group>
        </div>
      </el-col>
      <el-col :span="24" style="margin: 10px 25px;font-size:14px;color:#807e7e;">
        {{ $t('DeviceManage.ClientPolicy.TriggeringCondition') }}
        <span style="font-size: 12px; color: #807e7e">{{ $t('DeviceManage.ClientPolicy.SystemFunction.Message1') }}</span>
      </el-col>
    </el-row>
    <div style="display: flex; padding: 0 43px">
      <span style="color: #f66c6c;margin-right:5px;">*</span>
      <span style="width: 73px;font-size:14px;color:#807e7e;">{{ $t('DeviceManage.ClientPolicy.ResponseAction') }}</span>
      <div style="width: 500px; margin: 0 10px 0 0px">
        <el-table ref="multipleTable" :data="dictData" stripe size="mini" tooltip-effect="dark" class="sort_table"
          :header-cell-style="{ background: '#f7f8fa' }" style="width: 100%">
          <el-table-column :label="$t('DeviceManage.ClientPolicy.SerialNumber')" type="index" width="50" align="center">
          </el-table-column>
          <el-table-column header-align="left" align="left" prop="name"
            :label="$t('DeviceManage.ClientPolicy.ResponseAction1')" width="160" show-overflow-tooltip>
            <template v-slot="{ row }">
              <template v-if="row.name == '0'">
                {{ $t('DeviceManage.ClientPolicy.ResponseActionName0') }}
              </template>
              <template v-if="row.name == 1"> {{ $t('DeviceManage.ClientPolicy.ResponseActionName1') }} </template>
              <template v-if="row.name == 2"> {{ $t('DeviceManage.ClientPolicy.ResponseActionName2') }} </template>
              <template v-if="row.name == 3"> {{ $t('DeviceManage.ClientPolicy.ResponseActionName3') }} </template>
              <template v-if="row.name == 4"> {{ $t('DeviceManage.ClientPolicy.ResponseActionName4') }} </template>
              <template v-if="row.name == 5"> {{ $t('DeviceManage.ClientPolicy.ResponseActionName5') }} </template>
              <template v-if="row.name == 6"> {{ $t('DeviceManage.ClientPolicy.ResponseActionName6') }} </template>
            </template>
          </el-table-column>
          <el-table-column header-align="left" align="left" prop="timeOut" :label="$t('DeviceManage.ClientPolicy.LayTime1')" width="120" show-overflow-tooltip>
            <template v-slot="{ row }">
              {{ row.timeOut }}
            </template>
          </el-table-column>
          <el-table-column header-align="left" align="left" prop="name" :label="$t('public.Controls')" show-overflow-tooltip>
            <template v-slot="{ row }">
              <img v-if="row.flag && ruleForm.radio == 1" @click="deletes(row)" src="@/images/icon_del.png" alt="" style="
                  width: 15px;
                  height: 15px;
                  margin-left: 5px;
                  cursor: pointer;
                " />
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-button v-if="flagStatus" style="border-radius: 18px; width: 100px; height: 35px" size="small" type="primary"
        icon="el-icon-plus" @click="installFlag = true">{{$t('DeviceManage.ClientPolicy.ResponseAction1')}}</el-button>
    </div>
    <Eldialog v-if="installFlag" @handleClose="handleClose" :title="$t('DeviceManage.ClientPolicy.MessageError1')" :dialogVisible="installFlag"
      :btnTitle="$t('public.Join')" :flagbtn="true" :cancelBtn="true"  :num="'110px'"  :determineBtn="true" @determine="determine">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm"
        style="padding: 0 120px;height:100px;">
        <el-form-item :label="$t('DeviceManage.ClientPolicy.ResponseAction')">
          <el-select size="small" style="width:215px;" v-model="value" placeholder="$t('DeviceManage.ClientPolicy.MessageError14')">
            <el-option v-for="item in options" :key="item.value" :label="$t(`DeviceManage.ClientPolicy.Selectoptions.${item.label}`)" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('DeviceManage.ClientPolicy.LayTime')" prop="frequency">
          <el-input style="width: 85px; margin-right: 10px" size="small" v-model="ruleForm.frequency"></el-input>
          <el-select size="small" style="width: 120px" v-model="ruleForm.valueTime" placeholder="$t('DeviceManage.ClientPolicy.MessageError14')">
            <el-option v-for="item in time" :key="item.value" :label="$t(`DeviceManage.ClientPolicy.time.${item.label}`)" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </Eldialog>
    <dialog-info :dialogInfo="visibaelFlag" @handleClose="dialogClose" @determine="onchose" :titleInfo="titleInfo"
      :width="'240px'" /><!-- 提示消息组件 -->
  </div>
</template>

<script>
import dialogInfo from '@/components/promptMessage/index'
import Eldialog from '@/components/elDialog'
export default {
  components: {
    Eldialog,
    dialogInfo
  },
  props: {
    dataList: {
      type: Object,
      default: () => { }
    },
    version: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      content: [
        {
          operType: '0',
          frequency: '0',
          frequencyType: 'min'
        }
      ],
      functionlist: [],
      visibaelFlag: false,
      options: [
        {
          value: '1',
          label: '告警提示'
        },
        {
          value: '2',
          label: '清除数据'
        },
        {
          value: '3',
          label: '强制注销'
        },
        {
          value: '4',
          label: '停用设备'
        },
        {
          value: '5',
          label: '恢复出厂'
        },
        {
          value: '6',
          label: '禁止访问'
        }
      ],
      time: [
        {
          value: 'min',
          label: '分钟'
        },
        {
          value: 'hour',
          label: '小时'
        },
        {
          value: 'day',
          label: '日'
        }
      ],
      value: '1',
      activeName: 'first',
      flagStatus: false,
      installFlag: false,
      titleInfo: '',
      flagOp: true,
      dictData: [
        {
          name: '0',
          timeOut: this.$t('DeviceManage.ClientPolicy.ZeroMin'),
          flag: false
        }
      ],
      ruleForm: {
        name: '',
        radio: '0',
        valueTime: 'min',
        frequency: '1'
      },
      rules: {
        frequency: [
          { required: true, message: this.$t('DeviceManage.ClientPolicy.MessageError16'), trigger: 'blur' },
          {
            required: true,
            pattern: /^([1-9]\d*|[0]{1,1})$/,
            message: this.$t('DeviceManage.ClientPolicy.MessageError17'),
            trigger: 'blur'
          }
        ]
      }
    };
  },
  created() {
    if (this.dataList && this.dataList.use == 1) {
      this.flagStatus = true
      this.ruleForm.radio = this.dataList.use
      this.functionlist = this.dataList.functionlist
      this.content = this.dataList.content
      let data = []
      this.dataList.content.forEach((item, index) => {
        data.push({
          name: item.operType,
          timeOut:
            item.frequencyType == 'min'
              ? item.frequency + this.$t('DeviceManage.ClientPolicy.Min')
              : item.frequencyType == 'hour'
                ? item.frequency + this.$t('DeviceManage.ClientPolicy.Hour')
                : item.frequencyType == 'day'
                  ? item.frequency + this.$t('DeviceManage.ClientPolicy.Day')
                  : '',
          flag: index !== 0
        })
      })
      this.dictData = data
    }
  },
  methods: {
    TheCallback() {
      this.$emit('submitSave', {
        flag: this.ruleForm.radio,
        functionlist: this.functionlist,
        content: this.content
      })
    },
    changeSelect() {
      this.TheCallback()
    },
    handleClick(tab, event) {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
        } else {
          this.$nextTick(() => {
            this.activeName = 'first'
          })
        }
      })
    },
    // 设备越狱
    changeEvent(val) {
      this.TheCallback()
      if (val == 1) {
        this.flagStatus = true
      } else {
        this.flagStatus = false
      }
    },
    // 保存
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert('submit!')
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    deletes(val) {
      this.dictData.forEach((item, i) => {
        if (item.name === val.name) {
          // i 为选中的索引
          this.dictData.splice(i, 1)
          this.content.splice(i, 1)
        }
      })
      this.TheCallback()
    },
    determine() {
      this.flagOp = true
      for (let index = 0; index < this.dictData.length; index++) {
        if (this.dictData[index].name == this.value) {
          this.$message({
            message: this.$t('DeviceManage.ClientPolicy.MessageError13'),
            type: 'error',
            offset: 100
          })
          this.flagOp = false
        }
      }
      if (this.flagOp) {
        this.$nextTick(() => {
          this.dictData.push({
            name: this.value,
            timeOut:
              this.ruleForm.valueTime == 'min'
                ? this.ruleForm.frequency + this.$t('DeviceManage.ClientPolicy.Min')
                : this.ruleForm.valueTime == 'hour'
                  ? this.ruleForm.frequency + this.$t('DeviceManage.ClientPolicy.Hour')
                  : this.ruleForm.valueTime == 'day'
                    ? this.ruleForm.frequency + this.$t('DeviceManage.ClientPolicy.Day')
                    : '',
            flag: true
          })
          this.content.push({
            operType: this.value,
            frequency: this.ruleForm.frequency,
            frequencyType: this.ruleForm.valueTime
          })
        })
        this.$emit('submitSave', {
          flag: this.ruleForm.radio,
          functionlist: this.functionlist,
          content: this.content
        })
      }
    },
    handleClose() {
      this.$set(this.ruleForm, 'valueTime', 'min')
      this.$set(this.ruleForm, 'frequency', '1')
      this.installFlag = false
    },
    dialogClose() {
      this.visibaelFlag = false
    },
    onchose() {
      this.visibaelFlag = false
    }
  }
}
</script>

<style lang="scss" scoped>::v-deep .el-form-item {
  margin-bottom: 10px !important;
}</style>